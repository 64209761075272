import {
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { setFirstName, setLastName, setPassword, setEmail } from '../reduxSlice';
import {
  setFirstName,
  setLastName,
  setPassword,
  setEmail,
  registerUserAsync,
  authState,
} from "../reduxSlice";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        Home
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector(authState);
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(registerUserAsync({ navigate }));
  };

  const handleClick = () => {
    navigate("/signin");
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Link underline="none" color="inherit" href="/">
            <Typography
              className="fw-semibold"
              variant="h4"
              component="h4"
              color="#C2B9B0"
            >
              BIZ BLOGS
            </Typography>
          </Link>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={(e) => {
                    dispatch(setFirstName({ value: e.target.value }));
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  onChange={(e) => {
                    dispatch(setLastName({ value: e.target.value }));
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => {
                    dispatch(setEmail({ value: e.target.value }));
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(e) => {
                    dispatch(setPassword({ value: e.target.value }));
                  }}
                />
              </Grid>
            </Grid>
            <LoadingButton
              loading={loading}
              loadingIndicator="SignUp…"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
               Sign Up
            </LoadingButton>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <Link
                  onClick={handleClick}
                  variant="body2"
                  sx={{ cursor: "pointer" }}
                >
                  {"Already Have an Account? Sign In"}
                </Link>
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", flexDirection: "column", m: "1rem 0 0 0" }}
            >
              <Divider color="primary">or sign up with</Divider>
              <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                <FacebookRoundedIcon
                  sx={{
                    fontSize: "4rem",
                    p: "0.5rem",
                    borderRadius: "50%",
                    bgcolor: "#f4f5f9",
                    color: "#4267B2",
                  }}
                />
                <GoogleIcon
                  color="primary"
                  sx={{
                    mx: "1rem",
                    fontSize: "4rem",
                    p: "0.5rem",
                    borderRadius: "50%",
                    bgcolor: "#f4f5f9",
                  }}
                />
                <LinkedInIcon
                  sx={{
                    fontSize: "4rem",
                    p: "0.5rem",
                    borderRadius: "50%",
                    bgcolor: "#f4f5f9",
                    color: "#0A66C2",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Copyright sx={{ mt: 4 }} />
      </Container>
    </ThemeProvider>
  );
};

export default Signup;
