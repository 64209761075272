import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  postPopularBlogAPI,
  getPopularBlogAPI,
  deletePopularBlogAPI,
  movePopularBlogAPI,
  updateSelectedPopularBlogAPI,
  getSelectedPopularBlogAPI,
} from "./reduxAPI";

const initialState = {
  date: "",
  category: "",
  blogForm: false,
  section: [
    {
      heading: "",
      body: "",
      image: "",
    },
  ],
  loading: false,
  popularCollection: "",
  exploreData: "",
};

export const postPopularBlogAsync = createAsyncThunk(
  "popularBlog/postPopularBlogAPI",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const token = window.sessionStorage.getItem("token");
      const { section, date, category } = getState().popularBlog;
      const response = await postPopularBlogAPI(token, section, date, category);
      dispatch(clearState({}));
      dispatch(getPopularBlogAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

// export const updatePopularBlogAsync = createAsyncThunk(
//     "blogList/updateSelectedPopularBlogAPI",
//     async ({ pass, navigate }, { dispatch, getState, rejectWithValue }) => {
//       try {
//         const { section, date, category } = getState().popularBlog;
//         const response = await updateSelectedPopularBlogAPI(pass, section, date, category);
//         console.log(response)
//         dispatch(clearState({}))
//         dispatch(getPopularBlogAsync({}));
//         return response
//       } catch (error) {
//         if (error.name === "FirebaseError") {
//           console.log(error.code, error.message);
//         }
//         return rejectWithValue(error);
//       }
//     }
//   )

export const updatePopularBlogAsync = createAsyncThunk(
  "popularBlog/updateSelectedPopularBlogAPI",
  async ({ pass, navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { section, date, category } = getState().popularBlog;
      const response = await updateSelectedPopularBlogAPI(
        pass,
        section,
        date,
        category
      );
      console.log(response);
      dispatch(clearState({}));
      dispatch(getPopularBlogAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getPopularBlogAsync = createAsyncThunk(
  "popularBlog/getPopularBlogAPI",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const token = window.sessionStorage.getItem("token");
      const response = await getPopularBlogAPI(token);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

// export const getSelectedPopularBlogAsync = createAsyncThunk(
//     "explorepage/getSelectedPopularBlogAPI",
//     async ({ pass }, { dispatch, getState, rejectWithValue }) => {
//       try {
//         const response = await getSelectedPopularBlogAPI(pass);
//         return response;
//       } catch (error) {
//         if (error.name === "FirebaseError") {
//           console.log(error.code, error.message);
//         }
//         return rejectWithValue(error);
//       }
//     }
//   );

// export const deletePopularBlogAsync = createAsyncThunk(
//     "popularBlog/deletePopularBlogAPI",
//     async ({ pass, deletePass }, { dispatch, getState, rejectWithValue }) => {
//         try {
//             const response = await deletePopularBlogAPI(pass, deletePass);
//             dispatch(getPopularBlogAsync({}));
//             return response;
//         } catch (error) {
//             if (error.name === "FirebaseError") {
//                 console.log(error.code, error.message);
//             }
//             return rejectWithValue(error);
//         }
//     }
// );

export const deletePopularBlogAsync = createAsyncThunk(
  "popularBlog/deletePopularBlogAPI",
  async ({ pass }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { section } = getState().popularBlog;
      const response = await deletePopularBlogAPI(pass, section);
      dispatch(getPopularBlogAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const movePopularBlogAsync = createAsyncThunk(
  "popularBlog/movePopularBlogAPI",
  async ({ pass }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await movePopularBlogAPI(pass);
      dispatch(getPopularBlogAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const popularBlogSlice = createSlice({
  name: "popularBlog",
  initialState,
  reducers: {
    setImage: (state, action) => {
      const index = action.payload.index;
      state.section[index].image = action.payload.value;
    },
    setDate: (state, action) => {
      state.date = action.payload.value;
    },
    setCategory: (state, action) => {
      state.category = action.payload.value;
    },
    setHeading: (state, action) => {
      const index = action.payload.index;
      state.section[index].heading = action.payload.value;
    },
    setBody: (state, action) => {
      const index = action.payload.index;
      state.section[index].body = action.payload.value;
    },
    setSectionObject: (state, action) => {
      state.section = [
        ...state.section,
        {
          heading: "",
          body: "",
          image: "",
        },
      ];
    },
    updateSectionObject: (state, action) => {
      state.section = action.payload.value;
    },
    setBlogForm: (state, action) => {
      state.blogForm = action.payload.value;
    },
    clearState: (state) => {
      state.date = "";
      state.blogForm = false;
      state.category = "";
      state.section = [
        {
          heading: "",
          body: "",
          image: "",
        },
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postPopularBlogAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(postPopularBlogAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postPopularBlogAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getPopularBlogAsync.pending, (state, action) => {
        state.loading = true;
        state.popularCollection = "";
      })
      .addCase(getPopularBlogAsync.fulfilled, (state, action) => {
        state.loading = false;
        action.payload.data.forEach((item) => {
          state.popularCollection = [...state.popularCollection, item].sort(
            function (a, b) {
              var c = new Date(a.selectedDate);
              var d = new Date(b.selectedDate);
              return d - c;
            }
          );
        });
      })
      .addCase(getPopularBlogAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  setImage,
  setDate,
  setHeading,
  setBody,
  setBlogForm,
  setSectionObject,
  clearState,
  setCategory,
  updateSectionObject,
} = popularBlogSlice.actions;
export const popularBlogState = (state) => state.popularBlog;
export default popularBlogSlice.reducer;
