import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Grid } from "@mui/material";

import Post from "./Post";
import { useSelector } from "react-redux";
// import { postsState } from "../../../app/postsSlice";
// import { blogListState } from "../BlogsList/reduxSlice";
import { homepageState } from "../../Pages/Homepage/reduxSlice";

function HeroSection() {
  // const { posts } = useSelector(postsState);
  const { collection } = useSelector(homepageState);
  const navigate = useNavigate();

  const handleClick = (name, id) => {
    navigate(`/${name}+${id}`);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ my: 2, pt: 3, p: { xs: 1, sm: 2 } }}>
        <Grid container spacing={2}>
          <Grid item lg={8} md={7} sm={12} xs={12}>
            <Box sx={{ height: 500, width: "100%" }}>
              {collection
                ? collection?.slice(0, 1).map((item, index) => (
                    <Post
                      pass={item.id}
                      key={index}
                      title={item.section[0]?.heading}
                      body={item.section[0]?.body}
                      name={item.auth}
                      img={item.section[0]?.img.Location}
                      date={item.selectedDate}
                      handleClick={() => {
                        const name = item.section[0]?.heading;
                        const id = item.id;
                        handleClick(name, id);
                      }}
                    />
                  ))
                : null}
            </Box>
          </Grid>
          {/* aside section */}
          <Grid
            item
            lg={4}
            md={5}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 3,
              "& .MuiTypography-h5": {
                color: "white",
                fontSize: "20px",
              },
            }}
          >
            {collection
              ? collection?.slice(1, 3).map((item, index) => (
                  <Post
                    pass={item.id}
                    key={index}
                    title={item.section[0]?.heading}
                    body={item.section[0]?.body}
                    name={item.auth}
                    img={item.section[0]?.img.Location}
                    date={item.selectedDate}
                    handleClick={() => {
                      const name = item.section[0]?.heading;
                      const id = item.id;
                      handleClick(name, id);
                    }}
                  />
                ))
              : null}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
export default HeroSection;
