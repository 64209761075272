import { Box, Typography, IconButton } from "@mui/material";
import React from "react";
import Comment from "../Comments/Comment";
// import { useSelector } from "react-redux";
import ShareIcon from "@mui/icons-material/Share";
import parse from "html-react-parser";
import { setShareLink } from "../../Components/ShareModal/reduxSlice";
import ShareModel from "../../Components/ShareModal/App";
import { useDispatch } from "react-redux";

export default function Post(props) {
  const dispatch = useDispatch();

  const [show, setShow] = React.useState(false);
  const handleShare = (name, id) => {
    const url = window.location;
    setShow(true);
    dispatch(
      setShareLink({
        // http://localhost:3000/sdasedsde+XvCyEcRDIxiHBHupRGis
        value: url.protocol + "//" + url.host + "/" + name + "+" + id,
      })
    );
  };
  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          pt: {
            xs: 20,
            md: 12,
          },
          display: "flex",
          backgroundImage: `linear-gradient(180deg, rgba(1,1,22,0) 0%, rgba(1,1,22,0.9) 89%), url("${props.img}")`,
          backgroundPosition: "cover",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        <Box
          className="multiLineLabel"
          sx={{
            p: { md: 2, xs: 1 },
            width: "100%",
            cursor: "pointer",
            mt: "auto",
          }}
        >
          <Typography
            className="fw-semibold textMaxLine"
            onClick={props.handleClick}
            variant="h5"
            component="h5"
            color="var(--color-blue)"
          >
            {props.title}
          </Typography>

          <Typography
            className="textMaxLineTwo"
            onClick={props.handleClick}
            variant="subtitle2"
            component="div"
            sx={{
              my: { md: "0.5rem", xs: "0.5rem" },
              color: "#fff",
            }}
          >
            {parse(props.body || "")}
          </Typography>

          <Comment
            pass={props.pass}
            section2Date={props.section2Date}
            section2Name={props.section2Name}
            popularPostDate={props.date}
            name={props.name}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 1,
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <IconButton
            size="small"
            sx={{
              color: "#ffffff",
            }}
            onClick={() => {
              const name = props.title;
              const id = props.pass;
              handleShare(name, id);
            }}
          >
            <ShareIcon />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ display: "none" }}>
        <ShareModel
          sx={{ display: "inline" }}
          show={show}
          handleClose={handleClose}
        />
      </Box>
    </>
  );
}
