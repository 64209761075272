import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteBlogAPI,
  getBlogAPI,
  postBlogAPI,
  getSelectedBlogAPI,
  updateSelectedBlogAPI,
  moveToPopularAPI,
} from "./reduxAPI";
const initialState = {
  date: "",
  category: "",
  blogForm: false,
  section: [
    {
      heading: "",
      body: "",
      image: "",
    },
  ],
  loading: false,
  collection: "",
  exploreData: "",
};

export const postBlogAsync = createAsyncThunk(
  "blogList/postBlogAPI",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const token = window.sessionStorage.getItem("token");
      const { section, date, category } = getState().blogList;
      const response = await postBlogAPI(token, section, date, category);
      console.log(response);
      dispatch(clearState({}));
      dispatch(getBlogAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const updateBlogAsync = createAsyncThunk(
  "blogList/updateSelectedBlogAPI",
  async ({ pass, navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { section, date, category } = getState().blogList;
      const response = await updateSelectedBlogAPI(
        pass,
        section,
        date,
        category
      );
      console.log(response);
      dispatch(clearState({}));
      dispatch(getBlogAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getBlogAsync = createAsyncThunk(
  "blogList/getBlogAPI",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const token = window.sessionStorage.getItem("token");
      const response = await getBlogAPI(token);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getSelectedBlogAsync = createAsyncThunk(
  "blogList/getSelectedBlogAPI",
  async ({ pass }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await getSelectedBlogAPI(pass);
      console.log("hiii from blogs");
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteBlogAsync = createAsyncThunk(
  "blogList/deleteBlogAPI",
  async ({ pass }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { section } = getState().blogList;
      const response = await deleteBlogAPI(pass, section);
      dispatch(getBlogAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const moveToPopularAsync = createAsyncThunk(
  "popularBlog/moveToPopularAPI",
  async ({ pass }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await moveToPopularAPI(pass);
      dispatch(getBlogAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const blogSlice = createSlice({
  name: "blogList",
  initialState,
  reducers: {
    setImage: (state, action) => {
      const index = action.payload.index;
      state.section[index].image = action.payload.value;
    },
    setDate: (state, action) => {
      state.date = action.payload.value;
    },
    setCategory: (state, action) => {
      state.category = action.payload.value;
    },
    setHeading: (state, action) => {
      const index = action.payload.index;
      state.section[index].heading = action.payload.value;
    },
    setBody: (state, action) => {
      const index = action.payload.index;
      state.section[index].body = action.payload.value;
    },
    setSectionObject: (state, action) => {
      state.section = [
        ...state.section,
        {
          heading: "",
          body: "",
          image: "",
        },
      ];
    },
    updateSectionObject: (state, action) => {
      state.section = action.payload.value;
    },
    setBlogListForm: (state, action) => {
      state.blogForm = action.payload.value;
    },
    clearState: (state) => {
      state.date = "";
      state.blogForm = false;
      state.category = "";
      state.section = [
        {
          heading: "",
          body: "",
          image: "",
        },
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postBlogAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(postBlogAsync.fulfilled, (state, action) => {
        state.loading = false;
        // state.collectionHeading = action.payload;
      })
      .addCase(postBlogAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getBlogAsync.pending, (state, action) => {
        state.loading = true;
        state.collection = "";
      })
      .addCase(getBlogAsync.fulfilled, (state, action) => {
        state.loading = false;
        action.payload.data.forEach((item) => {
          state.collection = [...state.collection, item].sort(function (a, b) {
            var c = new Date(a.selectedDate);
            var d = new Date(b.selectedDate);
            return d - c;
          });
        });
      })
      .addCase(getBlogAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getSelectedBlogAsync.pending, (state, action) => {
        state.loading = true;
        state.collection = "";
      })
      .addCase(getSelectedBlogAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.exploreData = action.payload.data;
      })
      .addCase(getSelectedBlogAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteBlogAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteBlogAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteBlogAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  setImage,
  setDate,
  setHeading,
  setBody,
  setBlogListForm,
  setSectionObject,
  clearState,
  setCategory,
  updateSectionObject,
} = blogSlice.actions;
export const blogListState = (state) => state.blogList;
export default blogSlice.reducer;
