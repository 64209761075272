import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";


export function postSocialLink( youtube, facebook, linkedin, twitter, instagram) {
    const postSocialLink = httpsCallable(functions, "postSocialLink");
    return postSocialLink({
      youtube, facebook, linkedin, twitter, instagram
    });
}
  
export function updateSocialLink(twitter, facebook, linkedin, youtube, instagram) {
    const updateSocialLink = httpsCallable(functions, "updateSocialLink");
    return updateSocialLink({
      twitter,
      facebook,
      linkedin,
      youtube,
      instagram
    });
}

export function getSocialLinks(sessionToken) {
    const getSocialLinks = httpsCallable(functions, "getSocialLinks");
    return getSocialLinks({sessionToken});
}