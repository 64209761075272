import React from "react";
import { Grid, TextField, Button, Container, Box, Tooltip, IconButton, } from "@mui/material";
import Typography from "@mui/material/Typography";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from "@mui/material/InputAdornment";
import BusinessIcon from "@mui/icons-material/Business";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardState,
  getDashboardAsync,
  postDashboardAsync,
} from "./reduxSlice";
import { setCompanyName, setEmail, setLogo, clearState } from "./reduxSlice";

const DashboardTab = () => {

  const { collection, loading } = useSelector(dashboardState);
  const [imgName, setImgName] = React.useState([]);
  const [toggle, setToggle] = React.useState("A")
  const dispatch = useDispatch();
  // console.log(collection);

  // const showLogo = () => {
  //   setInterval(() => {
  //     setToggle("A")
  //   }, 1000);
  // }

  React.useEffect(() => {
    dispatch(getDashboardAsync({}));
  }, [dispatch]);

  const handleChange = async (e) => {
    const file = e.target.files[0];
    setImgName(file.name);
    const convertedFile = await convertToBase64(file);
    const files = {
      file: convertedFile,
      name: file.name,
      type: file.type,
    };
    dispatch(setLogo({ value: files }));
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };
  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={4} sx={{ p: 2, alignItems: "center" }}>
          <Grid item xs={12}>
            <Typography variant="h4" component="h4">
              Dashboard
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Company Name"
              placeholder="Company Name"
              variant="outlined"
              defaultValue={collection?.companyName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessIcon color="action" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) =>
                dispatch(setCompanyName({ value: e.target.value }))
              }
            />
          </Grid>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <TextField
              type="email"
              fullWidth
              id="email"
              label="Email"
              required
              placeholder="Email address"
              variant="outlined"
              autoComplete="false"
              defaultValue={collection?.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailIcon color="action" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => dispatch(setEmail({ value: e.target.value }))}
            />
          </Grid>
          <Grid item xs={12} sx={{ width: "100%"}}>
            {
              (toggle === "A" && collection) ? (
              <Box sx={{display:'flex', 
              alignItems:'start', 
              justifyContent:'center', 
              flexDirection:'column',
              width:'100%',
              }}>
                <Typography component='h6' variant="p" my={1} >Logo preview</Typography>
                <Box sx={{position:'relative'}}>
                <Box  component='img'
                src={collection?.logo?.Location}
                alt="logo-preview"
                sx={{width:200, height:200, p:2, border:1, }}
                />
                <Tooltip title="Remove & upload new" placement="right-end">
                <IconButton size="small"
                sx={{ ml: 0.5,position:'absolute', zIndex:33, top:-12, right:-12, 
                backgroundColor:'grey',
                ":hover":{
                  backgroundColor:'#595959',
                  color:'white'
                }}}
                onClick={() => {
                  setToggle("B");
                }} >
                  <ClearIcon/>
                </IconButton>
                </Tooltip>
                </Box>
              </Box>
              ): (
                <TextField
                margin="normal"
                required
                fullWidth
                placeholder="No file chosen"
                value={imgName ? imgName : ""}
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Button size="small" variant="contained" component="label">
                        upload logo
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          type="file"
                          onChange={(e) => {handleChange(e);
                          }}
                        />
                      </Button>
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
                />
              )
            }
          </Grid>
          <Button
            onClick={() => {
            dispatch(postDashboardAsync({}));
            setToggle('A')}}
            variant="contained"
            startIcon={<SaveIcon />}
            sx={{ ml: 4, my: 4 }}
          >
            {collection?.logo?.Location ? "update" : "save"}
          </Button>
        </Grid>
      </Container>
    </>
  );
};

export default DashboardTab;
