import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";

export function postBlogAPI(token, section, date, category) {
  const postBlog = httpsCallable(functions, "postBlog");
  return postBlog({ token, section, date, category });
}

export function deleteBlogAPI(pass, section) {
  const deleteBlog = httpsCallable(functions, "deleteBlog");
  return deleteBlog({ pass, section });
}

export function getBlogAPI(token) {
  const getBlogs = httpsCallable(functions, "getBlogs");
  return getBlogs({ token });
}

export function getSelectedBlogAPI(pass) {
  const getSelectedBlog = httpsCallable(functions, "getSelectedBlog");
  return getSelectedBlog({ pass });
}

export function updateSelectedBlogAPI(pass, section, date, category) {
  const updateSelectedBlog = httpsCallable(functions, "updateBlog");
  return updateSelectedBlog({ pass,section, date, category });
}

export function moveToPopularAPI(pass) {
  const moveToPopular = httpsCallable(functions, "moveToPopular");
  return moveToPopular({ pass });
}