import { configureStore } from "@reduxjs/toolkit";
import postsSlice from "./postsSlice";
import DashboardSlice from "../components/Components/Dashboard/reduxSlice";
import authReducer from "../components/Components/Auth/reduxSlice";
import socialLinkReducer from "../components/Components/SocialLinks/reduxSlice";
import blogListReducer from "../components/Components/BlogsList/reduxSlice";
import profileReducer from "../components/Components/Profile/reduxSlice";
import contactUsReducer from "../components/Pages/ContactPage/reduxSlice";
import commentReducer from "../components/Components/CommentList/reduxSlice";
import requestCommentReducer from "../components/Components/Comments/reduxSlice";
import explorePageReducer from "../components/Pages/ExplorePage/reduxSlice";
import homepageReducer from "../components/Pages/Homepage/reduxSlice";
import categoryReducer from "../components/Components/CategoryList/reduxSlice";
import shareReducer from "../components/Components/ShareModal/reduxSlice";
import popularBlogReducer from "../components/Components/PopularList/reduxSlice"

export const store = configureStore({
  reducer: {
    posts: postsSlice,
    dashboard: DashboardSlice,
    auth: authReducer,
    socialLinks: socialLinkReducer,
    blogList: blogListReducer,
    profile: profileReducer,
    contact: contactUsReducer,
    comment: commentReducer,
    requestComment: requestCommentReducer,
    explorepage: explorePageReducer,
    homepage: homepageReducer,
    category: categoryReducer,
    share: shareReducer,
    popularBlog: popularBlogReducer
  },
});
