import {
  Box,
  Typography,
  Button,
  Popover,
  Paper,
  Avatar,
  TextField,
  IconButton,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";
import { requestCommentAsync, setComment } from "./reduxSlice";
import { useDispatch } from "react-redux";
import moment from "moment/moment";

const Comment = (props) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorcommentEl, setAnchorcommentEl] = useState(null);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };
  const handleCommentClick = (event) => {
    setAnchorcommentEl(event.currentTarget);
  };

  const handleCommentClose = () => {
    setAnchorcommentEl(null);
  };
  const profileopen = Boolean(anchorEl);
  const profileid = profileopen ? "profile-popover" : undefined;

  const commentopen = Boolean(anchorcommentEl);
  const commentid = commentopen ? "comment-popover" : undefined;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Button
          className="text-nowrap"
          disableRipple
          disableTouchRipple
          onClick={handleProfileClick}
          sx={{
            p: "0",
            color: props.color ? props.color : "#d8d0c8",
            mr: "0.6rem",
            textTransform: "capitalize",
            fontSize: {
              lg: "0.8rem",
              md: "0.9rem",
              sm: "0.9rem",
              xs: "0.7rem",
            },
          }}
          size="small"
          startIcon={
            <PersonOutlineOutlinedIcon
              sx={{
                width: { xs: "1rem" },
                mr: -0.5,
                mb: 0.3,
              }}
            />
          }
        >
          {props.popularPostName ? props.popularPostName : ""}
          {props.latestName ? props.latestName : ""}
          {props.horizontalName ? props.horizontalName : ""}
          {props.section2Name ? props.section2Name : ""}
          {props.prevBlogsName ? props.prevBlogsName : ""}
          {props.name ? props.name : ""}
        </Button>
        <Popover
          id={profileid}
          open={profileopen}
          anchorEl={anchorEl}
          onClose={handleProfileClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Paper sx={{ display: "flex", p: "1rem" }}>
            <Avatar sx={{ textTransform: "uppercase" }}>
              {props?.popularPostName
                ?.split(" ")
                .map((n) => n[0])
                .join("")}
              {props?.latestName
                ?.split(" ")
                .map((n) => n[0])
                .join("")}
              {props?.horizontalName
                ?.split(" ")
                .map((n) => n[0])
                .join("")}
              {props?.section2Name
                ?.split(" ")
                .map((n) => n[0])
                .join("")}
              {props?.prevBlogsName
                ?.split(" ")
                .map((n) => n[0])
                .join("")}
              {props?.name
                ?.split(" ")
                .map((n) => n[0])
                .join("")}
            </Avatar>
            <Box sx={{ ml: "1rem", display: "flex", flexDirection: "column" }}>
              <Typography sx={{ textTransform: "capitalize" }}>
                {props.popularPostName ? props.popularPostName : ""}
                {props.latestName ? props.latestName : ""}
                {props.horizontalName ? props.horizontalName : ""}
                {props.section2Name ? props.section2Name : ""}
                {props.prevBlogsName ? props.prevBlogsName : ""}
                {props.name ? props.name : ""}
              </Typography>
              <Typography
                sx={{ textTransform: "capitalize", fontSize: "0.8rem" }}
              >
                More about {props.popularPostName ? props.popularPostName : ""}
                {props.latestName ? props.latestName : ""}
                {props.horizontalName ? props.horizontalName : ""}
                {props.section2Name ? props.section2Name : ""}
                {props.prevBlogsName ? props.prevBlogsName : ""}
                {props.name ? props.name : ""}
              </Typography>
            </Box>
          </Paper>
        </Popover>
        <Button
          disableRipple
          className="text-nowrap pe-none"
          sx={{
            p: "0",
            color: props.color ? props.color : "#d8d0c8",
            textTransform: "none",
            mr: "0.6rem",
            fontSize: {
              lg: "0.8rem",
              md: "0.9rem",
              sm: "0.9rem",
              xs: "0.7rem",
            },
          }}
          size="small"
          startIcon={
            <CalendarTodayOutlinedIcon
              sx={{ width: { xs: "1rem" }, mr: -0.5, mb: 0.3 }}
            />
          }
        >
          {props.section2Date
            ? moment(props.section2Date).format("MMM DD YYYY")
            : ""}

          {props.popularPostDate
            ? moment(props.popularPostDate).format("MMM DD YYYY")
            : ""}

          {props.latestDate
            ? moment(props.latestDateDate).format("MMM DD YYYY")
            : ""}

          {props.horizontalDate
            ? moment(props.horizontalDate).format("MMM DD YYYY")
            : ""}

          {props.prevBlogsDate
            ? moment(props.prevBlogsDate).format("MMM DD YYYY")
            : ""}
        </Button>
        {window.sessionStorage.getItem("token") ? (
          <Button
            onClick={handleCommentClick}
            sx={{
              p: "0",
              color: props.color ? props.color : "#d8d0c8",
              textTransform: "none",
              fontSize: {
                lg: "0.8rem",
                md: "0.9rem",
                sm: "0.9rem",
                xs: "0.7rem",
              },
            }}
            size="small"
            startIcon={
              <ChatBubbleOutlineOutlinedIcon
                sx={{ width: { xs: "1rem" }, mr: -0.5 }}
              />
            }
          >
            Comments
          </Button>
        ) : null}

        <Popover
          id={commentid}
          open={commentopen}
          anchorEl={anchorcommentEl}
          onClose={handleCommentClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Paper
            sx={{
              width: "350px",
              p: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Avatar sx={{ textTransform: "uppercase" }}>
              {props?.popularPostName
                ?.split(" ")
                .map((n) => n[0])
                .join("")}
              {props?.latestName
                ?.split(" ")
                .map((n) => n[0])
                .join("")}
              {props?.horizontalName
                ?.split(" ")
                .map((n) => n[0])
                .join("")}
              {props?.section2Name
                ?.split(" ")
                .map((n) => n[0])
                .join("")}
              {props?.prevBlogsName
                ?.split(" ")
                .map((n) => n[0])
                .join("")}
              {props?.name
                ?.split(" ")
                .map((n) => n[0])
                .join("")}
            </Avatar>
            <TextField
              multiline
              variant="standard"
              onChange={(e) => {
                dispatch(setComment({ value: e.target.value }));
              }}
            />
            <IconButton
              onClick={() => {
                const pass = props.pass;
                dispatch(requestCommentAsync({ pass }));
                handleCommentClose();
              }}
            >
              <SendIcon />
            </IconButton>
          </Paper>
        </Popover>
      </Box>
    </>
  );
};

export default Comment;
