import * as React from "react";
import {
  Box,
  Container,
  Paper,
  Skeleton,
} from "@mui/material";
import Navbar from "../../../Components/Navbar/Navbar";
import Footer from "../../../Components/Footer/Footer";
import Divider from "@mui/material/Divider";
import Section2Skeleton from "../../../Components/Section2/Skeleton/App";


function ExplorePageSkeleton() {
  // const { posts } = useSelector(postsState);
  const post = [1, 2, 3]

  return (
    <>
      <Navbar />
      <Container
        maxWidth="xl"
        sx={{ my: { sm: 4, xs: 1 }, px: { xs: 1, sm: 2, md: 3 } }}
      >
        <Box component="div" sx={{ p: 1 }}>
          {/* title */}
                <Box sx={{height:{md:540, xs:'100%'}, width:'100%', mx:'auto'}}>
                  <Skeleton variant="rectangular" sx={{ height: "100%", width: "100%", pt: { xs: 20, md: 12, }}} />
                </Box>
                <Skeleton variant="text" sx={{ fontSize: '2rem', my: { xs: 1, sm: 2 }  }} />

                {/* <Comment /> */}
                <Box sx={{ display: "flex", gap: { sm: 2, xs: 0 } }}>
                  <Skeleton variant="text" sx={{ fontSize: '1rem', mr: "0.6rem", width:110 }} />
                  <Skeleton variant="text" sx={{ fontSize: '1rem', mr: "0.6rem", width:110 }} />
                  <Skeleton variant="text" sx={{ fontSize: '1rem', mr: "0.6rem", width:110 }} />
                </Box>

                {/* body */}
                <Skeleton variant="text" sx={{ fontSize: '1rem', my: { xs: 1, sm: 2 }  }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', my: { xs: 1, sm: 2 }  }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', my: { xs: 1, sm: 2 }  }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', my: { xs: 1, sm: 2 }  }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', my: { xs: 1, sm: 2 }  }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', my: { xs: 1, sm: 2 }  }} />

          {/* <Divider  /> */}
          <Divider variant="fullWidth" sx={{ py: 2, my: 3 }}></Divider>

          {/* all comments box */}
          <Paper
            sx={{
              boxShadow: '0px -1px 4px -1px rgb(0 0 0 / 12%), -2px 5px 2px -5px rgb(0 0 0 / 9%), 0px 1px 13px -10px rgb(0 0 0 / 7%)',
              height:450,
              mt:1,
              px: {md:2, xs:0},
              pt:2,
              pb:2,
              mx:{md:4,xs:0},
              display: "flex",
              flexDirection: { md: "row", sm: "column", xs: "column" },
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", px: 1, mb:2, width:'100%', mt:4 }}>
              {post.map((comment) => {
                return (
                  <Box
                    sx={{
                      pl: 2,
                      my: "0.5rem",
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        width:'100%'
                      }}
                    >
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="text" sx={{ fontSize: '1.5rem', width:150 }} />
                    </Box>
                    <Divider variant="fullWidth" sx={{ my: 0.5, borderBottomWidth: 3 }}></Divider>
                    <Skeleton variant="rounded" 
                    sx={{ height:50, ml: {lg:6, xs:0}, p: 0.3,}} />
                  </Box>
                );
              })}
            </Box>
          </Paper>
          <Box sx={{px:{md:4, xs:1}, pt:6,pb:2, display:'flex', alignItems:'center', gap:2}}>
          <Skeleton variant="circular" width={45} height={45} />
          <Skeleton variant="rounded" 
          sx={{ height:40, p: 0.3, width:'100%'}} />
            </Box>
        </Box>
      </Container>
      <Section2Skeleton />
      <Footer />
    </>
  );
}
export default ExplorePageSkeleton;
