import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography, Box, IconButton, styled,Container } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import { commentState, getRequestCommentAsync, approvedCommentAsync } from './reduxSlice';
import { useSelector, useDispatch } from 'react-redux';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

export default function CommentList() {
	const { collection } = useSelector(commentState)

	const dispatch = useDispatch()
	React.useEffect(
		() => {dispatch(getRequestCommentAsync({}))},
		[dispatch]
	)

	return (
		<Container maxWidth="lg" className="mt-5">
						
			<Typography variant='h4' component="h4" textAlign="left">Comments List</Typography>
			<TableContainer sx={{ my: "3rem", mx: "auto", maxWidth: "100%" }} component={Paper}>
				<Table aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell align="left">Serial no.</StyledTableCell>
							<StyledTableCell align="left">Comment</StyledTableCell>
							<StyledTableCell align="center">Profile</StyledTableCell>
							<StyledTableCell align="right">CheckBox</StyledTableCell>
							<StyledTableCell align="right">Delete</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							collection?.data?.map((coll, index) => {
								return (
									<StyledTableRow key={index}>
									<StyledTableCell align="left">{index + 1}</StyledTableCell>
									<StyledTableCell align="left">{coll.comment}</StyledTableCell>
									<StyledTableCell align="center">{coll.name}</StyledTableCell>
									<StyledTableCell align="right">
										<IconButton onClick={() => {
										const pass = coll.id;
										dispatch(approvedCommentAsync({pass}))
										}} aria-label="done">
											<DoneIcon color="primary" />
										</IconButton></StyledTableCell>
									<StyledTableCell align="right"><IconButton aria-label="delete"><DeleteIcon color="primary" /></IconButton></StyledTableCell>
								</StyledTableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</TableContainer>
		</Container>
	);
}