import * as React from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  CardContent,
  Card,
  IconButton,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import HorizontalCard from "./HorizontalCard";
import Comment from "../Comments/Comment";
import { useSelector } from "react-redux";
import { useState } from "react";
import parse from "html-react-parser";
import { homepageState } from "../../Pages/Homepage/reduxSlice";
import ShareIcon from "@mui/icons-material/Share";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { setShareLink } from "../../Components/ShareModal/reduxSlice";
import ShareModel from "../../Components/ShareModal/App";
import { useDispatch } from "react-redux";

export default function LatestPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { collection } = useSelector(homepageState);
  const sort = collection?.slice(3, 11);

  // const { posts } = useSelector(postsState);

  const [currentPage, setCurrentPage] = useState(1);
  const postPerPage = 2;

  const indexOfLastPage = currentPage * postPerPage;
  const indexOFFirstPost = indexOfLastPage - postPerPage;
  const currentPost = sort.slice(indexOFFirstPost, indexOfLastPage);
  let count = Math.ceil(sort.length / postPerPage);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleClick = (name, id) => {
    navigate(`/${name}+${id}`);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const [show, setShow] = React.useState(false);
  const handleShare = (name, id) => {
    const url = window.location;
    setShow(true);
    dispatch(
      setShareLink({
        // http://localhost:3000/sdasedsde+XvCyEcRDIxiHBHupRGis
        value: url.protocol + "//" + url.host + "/" + name + "+" + id,
      })
    );
  };
  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ my: 3, mb: 6, p: { xs: 0, sm: 2 } }}>
        <Typography
          variant="h5"
          component="div"
          className="fw-semibold"
          sx={{
            color: "#fff",
            background: "var(--color-black)",
            py: 2,
            pl: 3,
            mb: 3,
          }}
        >
          LATEST POST
        </Typography>

        <Grid container spacing={2}>
          {/* main hero section */}
          {collection[3] ? (
            <Grid item md={7} lg={8} xs={12} sx={{ position: "relative" }}>
              <Box
                className="rounded"
                sx={{
                  pr: { md: 3, xs: 0 },
                  width: "100%",
                  height: { sm: "100%", xs: "auto" },
                  display: "flex",
                  boxShadow: "-1px 0px 5px rgba(0, 0, 0, 0.25)",
                  flexDirection: "column",
                }}
              >
                {/* Two horizontal cards */}
                {collection
                  ? currentPost?.map((item, index) => {
                      return (
                        <HorizontalCard
                          key={index}
                          title={item.section[0]?.heading}
                          body={item.section[0]?.body}
                          name={item.auth}
                          img={item.section[0]?.img.Location}
                          date={item.selectedDate}
                          handleClick={() => {
                            const name = item.section[0]?.heading;
                            const id = item.id;
                            handleClick(name, id);
                          }}
                          handleShare={() => {
                            const name = item.section[0]?.heading;
                            const id = item.id;
                            handleShare(name, id);
                          }}
                        />
                      );
                    })
                  : null}
              </Box>

              {/* pagination */}
              <Pagination
                onChange={handleChange}
                count={count}
                page={currentPage}
                sx={{
                  mt: "1rem",
                  display: "flex",
                  justifyContent: { md: "flex-end", xs: "center" },
                  maxWidth: "100%",
                }}
                variant="outlined"
                shape="rounded"
                size="small"
              />
            </Grid>
          ) : null}

          {/* aside section */}
          <Grid item md={5} lg={4} sm={12} xs={12}>
            {collection
              ? collection?.slice(11, 12).map((item, index) => {
                  return (
                    <>
                      <Card
                        key={index}
                        className=" w-100 h-100 d-flex flex-column"
                        sx={{
                          alignSelf: "normal",
                          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                          p: { sm: 2, xs: 1 },
                          position: "relative",
                          mt: {
                            xs: 2,
                            sm: 5,
                            md: "auto",
                          },
                        }}
                      >
                        <Box
                          component="div"
                          sx={{
                            aspectRatio: "16/9",
                            width: "100%",
                            backgroundImage: `linear-gradient(0deg,rgba(0, 0, 0, 0.452) 0,#1e1e1e3d 42%,rgb(0 0 0 / 13%)), url("${item.section[0]?.img.Location}")`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                          alt=".."
                        />
                        <CardContent
                          className="multiLineLabel"
                          sx={{
                            display: "flex",
                            p: 1,
                            flexDirection: "column",
                            alignItems: "start",
                            cursor: "pointer",
                            backgroundColor: "#FFFFFF",
                            gap: 2,
                          }}
                        >
                          <Typography
                            className="textMaxLine fw-semibold"
                            onClick={() => {
                              const name = item.section[0]?.heading;
                              const id = item.id;
                              handleClick(name, id);
                            }}
                            variant="h5"
                            component="h5"
                            color="var(--color-blue)"
                          >
                            {item.section[0]?.heading}
                          </Typography>
                          <Comment
                            latestName={item.auth}
                            latestDate={item.selectedDate}
                            color={"#5d5d5d"}
                          />
                          <Typography
                            className="textMaxLineThree"
                            onClick={() => {
                              const name = item.section[0]?.heading;
                              const id = item.id;
                              handleClick(name, id);
                            }}
                            variant="body1"
                            component="div"
                            sx={{ color: "#5d5d5d" }}
                          >
                            {parse(item.section[0]?.body)}
                          </Typography>
                        </CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            p: 1,
                            position: "absolute",
                            right: 15,
                          }}
                        >
                          <IconButton
                            size="small"
                            sx={{ color: "white" }}
                            onClick={() => {
                              const name = item.section[0]?.heading;
                              const id = item.id;
                              handleShare(name, id);
                            }}
                          >
                            <ShareIcon />
                          </IconButton>
                          <ShareModel
                            sx={{ display: "inline" }}
                            show={show}
                            handleClose={handleClose}
                          />
                        </Box>
                      </Card>
                    </>
                  );
                })
              : null}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
