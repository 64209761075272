import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { requestCommentAPI } from "./reduxAPI";
const initialState = {
  comment: "",
  loading: false,
};

export const requestCommentAsync = createAsyncThunk(
  "requestComment/requestCommentAPI",
  async ({ pass }, { dispatch, getState, rejectWithValue }) => {
    try {
      const token = window.sessionStorage.getItem("token");
      const { comment } = getState().requestComment;
      const response = await requestCommentAPI(token, comment, pass);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const requestCommentSlice = createSlice({
  name: "requestComment",
  initialState,
  reducers: {
    setComment: (state, action) => {
      state.comment = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestCommentAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(requestCommentAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.comment = action.payload.value
      })
      .addCase(requestCommentAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setComment } = requestCommentSlice.actions;
export const requestCommentState = (state) => state.requestComment;
export default requestCommentSlice.reducer;
