import * as React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Popover,
  Paper,
  Avatar,
  TextField,
  IconButton,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Navbar from "../../Components/Navbar/Navbar";
import Section2 from "../../Components/Section2/Section2";
import Footer from "../../Components/Footer/Footer";
import { useSelector } from "react-redux";
import { useRef } from "react";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { useState } from "react";
import Divider from "@mui/material/Divider";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setParamId } from "../ExplorePage/reduxSlice";

import {
  explorePageState,
  getSelectedBlogAsync,
  getSelectedPopularBlogAsync,
} from "./reduxSlice";
import { getPopularBlogAsync } from "../../Components/PopularList/reduxSlice";
import { getSocialAsync } from "../../Components/SocialLinks/reduxSlice";
import { getBlogAsync } from "../Homepage/reduxSlice";
import { getDashboardAsync } from "../../Components/Dashboard/reduxSlice";
import { getCategoryAsync } from "../../Components/CategoryList/reduxSlice";
import parse from "html-react-parser";
import SkeletonComponent from "./Skeleton/App";
import { getRequestCommentAsync } from "../../Components/CommentList/reduxSlice";
import {
  requestCommentAsync,
  setComment,
} from "../../Components/Comments/reduxSlice";
import moment from "moment/moment";

function ExplorePage() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { exploreData, popularExploreData, loading } =
    useSelector(explorePageState);

  const { blogparam } = useParams();
  // const name = blogparam?.split("+")[0];
  const pass = blogparam?.split("+")[1];

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getSocialAsync({}));
    dispatch(getBlogAsync({}));
    dispatch(getDashboardAsync({}));
    dispatch(getCategoryAsync({}));
    dispatch(getRequestCommentAsync({}));
    dispatch(getPopularBlogAsync({}));
    // check()
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getSelectedBlogAsync({ pass })); // null
    dispatch(getSelectedPopularBlogAsync({ pass })); // popular
    dispatch(setParamId({ value: blogparam }));
  }, [dispatch, blogparam, pass]);

  const ref = useRef(null);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };
  const profileopen = Boolean(anchorEl);
  const profileid = profileopen ? "profile-popover" : undefined;

  const handleCommentClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [message, setMessage] = React.useState();

  const clearInput = (event) => {
    setMessage("");
  };

  return (
    <>
      {loading ? (
        <SkeletonComponent />
      ) : (
        <>
          <Navbar />
          <Container
            maxWidth="xl"
            sx={{ my: { sm: 4, xs: 1 }, px: { xs: 1, sm: 2, md: 3 } }}
          >
            <Box component="div">
              {/* title */}
              {(popularExploreData
                ? popularExploreData
                : exploreData
              )?.section?.map((item, index) => {
                return (
                  <>
                    <Container>
                      <Box
                        sx={{
                          height: { lg: 550, md: 450, sm: 300, xs: 250 },
                          width: { xs: "100%" },
                          mx: "auto",
                        }}
                      >
                        <Box
                          sx={{
                            height: "100%",
                            width: "100%",
                            pt: { xs: 20, md: 12 },
                            backgroundImage: `url("${item.img.Location}")`,
                            backgroundPosition: "top",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                        ></Box>
                      </Box>

                      <Typography
                        className="fw-bold"
                        variant="h5"
                        component="h5"
                        color="var(--color-blue)"
                        sx={{ my: { xs: 1, sm: 2 } }}
                      >
                        {item.heading}
                      </Typography>

                      {/* <Comment /> */}
                      {index === 0 ? (
                        <Box sx={{ display: "flex", gap: { sm: 2, xs: 0 } }}>
                          <Button
                            className="text-nowrap"
                            onClick={handleProfileClick}
                            sx={{
                              p: "0",
                              color: "#5d5d5d",
                              textTransform: "capitalize",
                              mr: "0.6rem",
                              fontSize: {
                                lg: "0.9rem",
                                md: "0.9rem",
                                sm: "0.9rem",
                                xs: "0.7rem",
                              },
                            }}
                            size="small"
                            startIcon={
                              <PersonOutlineOutlinedIcon
                                sx={{
                                  width: { xs: "1rem" },
                                  mr: "-0.3rem",
                                }}
                              />
                            }
                          >
                            {
                              (exploreData ? exploreData : popularExploreData)
                                .auth
                            }
                          </Button>
                          <Popover
                            id={profileid}
                            open={profileopen}
                            anchorEl={anchorEl}
                            onClose={handleProfileClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Paper sx={{ display: "flex", p: "0.6rem" }}>
                              <Avatar
                                sx={{
                                  width: "35px",
                                  height: "35px",
                                  textTransform: "uppercase",
                                }}
                              >
                                {(exploreData
                                  ? exploreData
                                  : popularExploreData
                                ).auth
                                  ?.split(" ")
                                  .map((n) => n[0])
                                  .join("")}
                              </Avatar>
                              <Box
                                sx={{
                                  ml: "1rem",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography>
                                  {" "}
                                  {
                                    (exploreData
                                      ? exploreData
                                      : popularExploreData
                                    ).auth
                                  }
                                </Typography>
                                <Typography sx={{ fontSize: "0.8rem" }}>
                                  {
                                    (exploreData
                                      ? exploreData
                                      : popularExploreData
                                    ).auth
                                  }
                                </Typography>
                              </Box>
                            </Paper>
                          </Popover>
                          <Button
                            disableRipple
                            className="text-nowrap pe-none"
                            sx={{
                              p: "0",
                              color: "#5d5d5d",
                              textTransform: "none",
                              mr: "0.6rem",
                              fontSize: {
                                lg: "0.9rem",
                                md: "0.9rem",
                                sm: "0.9rem",
                                xs: "0.7rem",
                              },
                            }}
                            size="small"
                            startIcon={
                              <CalendarTodayOutlinedIcon
                                sx={{ width: { xs: "1rem" }, mr: "-0.2rem" }}
                              />
                            }
                          >
                            {moment(
                              (exploreData ? exploreData : popularExploreData)
                                ?.selectedDate
                            ).format("DD MMM YYYY")}
                          </Button>
                          <Button
                            onClick={handleCommentClick}
                            sx={{
                              p: "0",
                              color: "#5d5d5d",
                              textTransform: "none",
                              fontSize: {
                                lg: "0.9rem",
                                md: "0.9rem",
                                sm: "0.9rem",
                                xs: "0.7rem",
                              },
                            }}
                            size="small"
                            startIcon={
                              <ChatBubbleOutlineOutlinedIcon
                                sx={{ width: { xs: "1rem" }, mr: "-0.2rem" }}
                              />
                            }
                          >
                            Comments
                          </Button>
                        </Box>
                      ) : null}

                      {/* body */}
                      <Typography
                        variant="body1"
                        component="p"
                        sx={{
                          my: { md: "1rem", xs: "0.5rem" },
                          textAlign: "justify",

                          color: "#5d5d5d",
                        }}
                      >
                        {parse(item.body)}
                      </Typography>
                    </Container>
                  </>
                );
              })}

              {/* <Divider  /> */}
              <Divider
                ref={ref}
                variant="fullWidth"
                sx={{ my: 3, borderBottomWidth: 3 }}
              ></Divider>
              <Typography
                sx={{ my: { md: 3, xs: 0 }, ml: { md: 5, xs: 1 } }}
                color="var(--color-blue)"
                variant="h6"
                component="h5"
              >
                {`Total Comments (${
                  exploreData
                    ? exploreData?.comments?.length
                      ? exploreData?.comments?.length
                      : 0
                    : popularExploreData?.comments?.length
                    ? popularExploreData?.comment?.length
                    : 0
                })`}
              </Typography>
              <Paper
                sx={{
                  boxShadow:
                    "0px -1px 4px -1px rgb(0 0 0 / 12%), -2px 5px 2px -5px rgb(0 0 0 / 9%), 0px 1px 13px -10px rgb(0 0 0 / 7%)",
                  maxHeight: 450,
                  overflowY: "scroll",
                  "::-webkit-scrollbar": {
                    width: "10px",
                  },
                  "::-webkit-scrollbar-thumb": {
                    background: "#1976d2a6",
                    borderRadius: "5px",
                  },
                  mt: 1,
                  px: { md: 2, xs: 0 },
                  pt: 2,
                  pb: 2,
                  mx: { md: 4, xs: 0 },
                  display: "flex",
                  flexDirection: { md: "row", sm: "column", xs: "column" },
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    px: 1,
                    mb: 2,
                    width: "100%",
                    mt: 4,
                  }}
                >
                  {(popularExploreData
                    ? popularExploreData
                    : exploreData
                  )?.comments?.map((comment) => {
                    return (
                      <Box
                        sx={{
                          pl: 2,
                          my: "0.5rem",
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            sx={{
                              width: "35px",
                              height: "35px",
                              textTransform: "uppercase",
                            }}
                          >
                            {comment.name
                              .split(" ")
                              .map((n) => n[0])
                              .join("")}
                          </Avatar>
                          <Typography
                            sx={{ fontWeight: "bold", textAlign: "left" }}
                            variant="subtitle1"
                          >
                            {comment.name}
                          </Typography>
                          <Typography variant="subtitle2" component="h5">
                            {moment(comment.date).fromNow()}
                          </Typography>
                        </Box>
                        <Divider
                          variant="fullWidth"
                          sx={{ my: 0.5, borderBottomWidth: 3 }}
                        ></Divider>
                        <Typography
                          sx={{
                            ml: 6,
                            p: 1.5,
                            my: 1,
                            backgroundColor: "#00000012",
                          }}
                          variant="subtitle2"
                        >
                          {comment.comment}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Paper>
              {window.sessionStorage.getItem("token") ? (
                <Box
                  sx={{
                    px: { md: 4, xs: 1 },
                    pt: 6,
                    pb: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Avatar
                    sx={{
                      width: "40px",
                      height: "40px",
                      textTransform: "uppercase",
                      p: 2,
                    }}
                  >
                    {(exploreData ? exploreData : popularExploreData)?.auth
                      ?.split(" ")
                      .map((n) => n[0])
                      .join("")}
                  </Avatar>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      dispatch(setComment({ value: e.target.value }));
                    }}
                    value={message}
                    placeholder="Add Comments.."
                    sx={{
                      "	.MuiOutlinedInput-root": { pr: 0 },
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& fieldset": {
                        borderRadius: 1,
                        borderColor: "black",
                        border: 1,
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            dispatch(requestCommentAsync({ pass }));
                            clearInput();
                          }}
                          size="large"
                        >
                          <SendIcon />
                        </IconButton>
                      ),
                      style: {
                        pr: "0rem !important",
                        mx: 2,
                      },
                    }}
                  />
                </Box>
              ) : (
                <Typography
                  sx={{ my: { md: 3, xs: 0 }, ml: { md: 5, xs: 1 } }}
                  className="fw-bold"
                  color="#5D5D5D"
                  variant="subtitle1"
                  component="p"
                >
                  SignIn / SignUp to submit comment
                </Typography>
              )}
            </Box>
          </Container>
          <Section2 />
          <Footer />
        </>
      )}
    </>
  );
}
export default ExplorePage;
