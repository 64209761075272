import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";

export function postPopularBlogAPI(token, section, date, category) {
  const postPopularBlog = httpsCallable(functions, "postPopular");
  return postPopularBlog({ token, section, date, category });
}

export function getPopularBlogAPI(token) {
  const getPopularBlog = httpsCallable(functions, "getPopular");
  return getPopularBlog({ token });
}

export function deletePopularBlogAPI(pass, section) {
  const deletePopularBlog = httpsCallable(functions, "deletePopular");
  return deletePopularBlog({ pass, section });
}

export function movePopularBlogAPI(pass) {
  const movePopularBlog = httpsCallable(functions, "movePopular");
  return movePopularBlog({ pass });
}

export function updateSelectedPopularBlogAPI(pass, section, date, category) {
  const updateSelectedPopularBlog = httpsCallable(functions, "updatePopular");
  return updateSelectedPopularBlog({ pass, section, date, category });
}
// export function getSelectedPopularBlogAPI(pass) {
//   const getSelectedPopularBlog = httpsCallable(functions, "getSelectedPopularBlog");
//   return getSelectedPopularBlog({ pass });
// }
