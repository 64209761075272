import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";

export function getRequestCommentAPI(token) {
  const getRequestComment = httpsCallable(functions, "getRequestComment");
  return getRequestComment({ token });
}

export function approvedComment(pass) {
  const approvedComment = httpsCallable(functions, "approvedComment");
  return approvedComment({ pass });
}


