import React from 'react'
import LatestPageSkeleton from '../../../Components/LatestPage/Skeleton/App'
import HeroSectionSkeleton from '../../../Components/HeroSection/Skeleton/App'
import Section2Skeleton from '../../../Components/Section2/Skeleton/App'
import Footer from '../../../Components/Footer/Footer'

const HomePageSkeleton = () => {
    
  return (
    <>
        <HeroSectionSkeleton />
        <LatestPageSkeleton />
        <Section2Skeleton />
        <Footer />
    </>
  )
}

export default HomePageSkeleton