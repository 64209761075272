import * as React from "react";
import { Box, Container, Grid, Skeleton } from "@mui/material";
import Navbar from "../../Navbar/Navbar";

function HeroSectionSkeleton() {

  return (
    <>
    <Navbar />
      <Container maxWidth="xl" sx={{ my: 2, pt: 3, p: { xs: 1, sm: 2 } }}>
        <Grid container spacing={2}>
          <Grid item lg={8} md={7} sm={12} xs={12}>
            <Box sx={{ maxHeight: {md:'100%' ,xs:'80%'}, width: "100%"}} >
              <Skeleton variant="rectangular" animation="wave" 
              sx={{height:{md:520, xs:245}, }} 
              />
            </Box>
          </Grid>

          {/* aside section */}
          <Grid
            item
            lg={4}
            md={5}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 3,
            }}
          >
            
            <Box sx={{ height:'100%', width: "100%"}} >
              <Skeleton variant="rectangular" animation="wave" height={245}
              />
            </Box>
            <Box sx={{ height:'100%', width: "100%"}} >
              <Skeleton variant="rectangular" animation="wave" height={245}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
export default HeroSectionSkeleton;
