import {
  Box,
  Paper,
  Typography,
  Container,
  Button,
  TextField,
  Skeleton,
} from "@mui/material";

const Section2Skeleton = () => {
  // const handleChange = (event, value) => {
  //   setCurrentPage(value);
  // };

  return (
    <>
      <Container maxWidth="xl" sx={{ my: 3, mb: 10, p: { xs: 0, sm: 2 } }}>
        <Typography
          variant="h5"
          component="div"
          className="fw-semibold"
          sx={{
            color: "#fff",
            background: "var(--color-black)",
            py: 2,
            pl: 3,
            mb: 3,
          }}
        >
          POPULAR POSTS
        </Typography>
        <Box
          sx={{
            m: 1,
            display: "flex",
            flexDirection: {
              lg: "row",
              md: "column",
              sm: "column",
              xs: "column",
            },
            gap: 2,
          }}
        >
          <Box
            sx={{
              m: "auto",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Skeleton
              sx={{ maxWidth: "100%", height: { lg: 400, sm: 300, xs: 200 } }}
              variant="rounded"
            ></Skeleton>
            <Skeleton
              sx={{ maxWidth: "100%", height: { lg: 400, sm: 300, xs: 200 } }}
              variant="rounded"
            ></Skeleton>
          </Box>
          <Paper sx={{ width: { xs: "100%", lg: "55%" }, boxShadow: 2 }}>
            <Typography
              variant="h6"
              component="div"
              className="fw-semibold"
              sx={{
                background: "var(--color-black)",
                color: "#fff",
                py: 2,
                pl: 2,
              }}
            >
              NEWSLETTER
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              sx={{ m: "20px 0", px: 2, color: "#bfbfbf" }}
            >
              Here, I focus on a range of items and features that we use in life
              without giving them a second thought.
            </Typography>
            <Box className=" px-2">
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                placeholder="Email Address"
                sx={{
                  "	.MuiOutlinedInput-root": { pr: 0 },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "black",
                    },
                  },
                  "& fieldset": {
                    borderRadius: 0,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <Button
                      size="large"
                      variant="contained"
                      sx={{
                        backgroundColor: "var(--color-blue) !important",
                        boxShadow: "none",
                        ":hover": {
                          boxShadow: "none",
                        },
                      }}
                    >
                      Subscribe
                    </Button>
                  ),
                  style: {
                    pr: "0rem !important",
                    mx: 2,
                    backgroundColor: "#00000017",
                  },
                }}
              />
            </Box>
            <Typography
              variant="subtitle1"
              component="p"
              px={2}
              sx={{ m: "20px 0", color: "#bfbfbf" }}
            >
              You can unsubscribe us at any time
            </Typography>
            <Typography
              variant="h6"
              component="div"
              className="fw-semibold"
              sx={{
                background: "var(--color-black)",
                color: "#fff",
                py: 2,
                pl: 2,
                mb: 3,
              }}
            >
              PREVIOUS BLOGS
            </Typography>
            <Skeleton
              sx={{ maxWidth: "100%", m: 1 }}
              variant="rounded"
              maxWidth={400}
              height={100}
            ></Skeleton>
            <Skeleton
              sx={{ maxWidth: "100%", m: 1 }}
              variant="rounded"
              maxWidth={400}
              height={100}
            ></Skeleton>
            <Skeleton
              sx={{ maxWidth: "100%", m: 1 }}
              variant="rounded"
              maxWidth={400}
              height={100}
            ></Skeleton>
            <Skeleton
              sx={{ maxWidth: "100%", m: 1 }}
              variant="rounded"
              maxWidth={400}
              height={100}
            ></Skeleton>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default Section2Skeleton;
