import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
const container = document.getElementById("root");
const root = createRoot(container);

let theme = createTheme({
  typography: {
    h1: {
      fontFamily: "Montserrat, sans-serif",
      color: "#000",
    },
    h2: {
      fontFamily: "Montserrat, sans-serif",
      color: "#000",
    },
    h3: {
      fontFamily: "Montserrat, sans-serif",
      color: "#000",
    },
    h4: {
      fontFamily: "Montserrat, sans-serif",
      color: "#000",
    },
    h5: {
      fontFamily: "Montserrat, sans-serif",
      color: "#000",
    },
    h6: {
      fontFamily: "Montserrat, sans-serif",
      color: "#000",
    },
    subtitle1: {
      fontFamily: "Montserrat, sans-serif",
      color: "#5D5D5D",
    },
    subtitle2: {
      fontFamily: "Montserrat, sans-serif",
      color: "#00000099",
    },
    body1: {
      fontFamily: "Montserrat, sans-serif",
      color: "#C2B9B0",
    },
    body2: {
      fontFamily: "Montserrat, sans-serif",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
    },
    caption: {
      fontFamily: "Montserrat, sans-serif",
    },
    overline: {
      fontFamily: "Montserrat, sans-serif",
    },
  },
});
theme = responsiveFontSizes(theme);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  // </React.StrictMode>
);
