import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteCategoryAPI, getCategoryAPI, postCategoryAPI, updateCategoryAPI } from "./reduxAPI";

const initialState = {
  category: "",
  categoryCollection: "",
  pass: "",
};

export const postCategoryAsync = createAsyncThunk(
  "category/postCategoryAPI",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { category } = getState().category;
      const response = await postCategoryAPI(category);
      dispatch(clearState({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const updateCategoryAsync = createAsyncThunk(
  "category/updateCategoryAPI",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { pass, category } = getState().category;
      const response = await updateCategoryAPI(pass, category);
      dispatch(clearState({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getCategoryAsync = createAsyncThunk(
  "category/getCategoryAPI",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const token = window.sessionStorage.getItem("token");
      const response = await getCategoryAPI(token);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteCategoryAsync = createAsyncThunk(
  "category/deleteCategoryAPI",
  async ({ pass }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await deleteCategoryAPI(pass);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategory: (state, action) => {
      state.category = action.payload.value;
    },
    setPass: (state, action) => {
      state.pass = action.payload.value;
    },
    clearState: (state, action) => {
      state.category = "";
      state.pass = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCategoryAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(postCategoryAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postCategoryAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getCategoryAsync.pending, (state, action) => {
        state.loading = true;
        state.categoryCollection = "";
      })
      .addCase(getCategoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        action.payload.data.forEach((item) => {
          state.categoryCollection = [...state.categoryCollection, item];
        });
      })
      .addCase(getCategoryAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setCategory, setPass, clearState } = categorySlice.actions;
export const categoryState = (state) => state.category;
export default categorySlice.reducer;
