import * as React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
import Comment from "../Comments/Comment";
import parse from "html-react-parser";
import ShareIcon from "@mui/icons-material/Share";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

function HorizontalCard(props) {
  const { title, body, date, img, name } = props;
  // const newdate = date.split(" ");
  // const commentDate = newdate.slice(1, 4);

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          height: "100%",
          boxShadow: 0,
          alignSelf: "normal",
          p: { sm: 2, xs: 1 },
        }}
      >
        <Box
          component="div"
          sx={{
            aspectRatio: "4/3",
            width: { xs: "100%", md: 200, lg: 250 },
            backgroundImage: `linear-gradient(0deg,rgba(0, 0, 0, 0.452) 0,#1e1e1e3d 42%,rgb(0 0 0 / 13%)), url("${img}")`,
            backgroundPosition: "cover",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
          }}
          alt=".."
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 1,
              position: "absolute",
              right: 0,
            }}
          >
            <IconButton
              size="small"
              sx={{ color: "white" }}
              onClick={props.handleShare}
            >
              <ShareIcon />
            </IconButton>
          </Box>
        </Box>
        <CardContent
          className="multiLineLabel"
          sx={{
            pl: { lg: 4, md: 1, sm: 3, xs: 1 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            cursor: "pointer",
            gap: 1,
          }}
        >
          <Typography
            className="textMaxLine fw-semibold"
            onClick={props.handleClick}
            variant="h5"
            component="h5"
            color="var(--color-blue)"
          >
            {title}
          </Typography>

          <Comment
            horizontalName={name}
            horizontalDate={date}
            color={"#5d5d5d"}
          />
          <Typography
            className="textMaxLineThree"
            onClick={props.handleClick}
            variant="body1"
            component="div"
            sx={{ color: "#5d5d5d" }}
          >
            {parse(body)}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
export default HorizontalCard;
