import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";

export function getSelectedBlogAPI(pass) {
  const getSelectedBlog = httpsCallable(functions, "getSelectedBlog");
  return getSelectedBlog({ pass });
}

export function getSelectedPopularBlogAPI(pass) {
  const getSelectedPopularBlog = httpsCallable(
    functions,
    "getSelectedPopularBlog"
  );
  return getSelectedPopularBlog({ pass });
}
