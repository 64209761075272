import * as React from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import Navbar from "../../Components/Navbar/Navbar";
import FooterComponent from "../../Components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { homepageState } from "../Homepage/reduxSlice";
import { useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment";
import BlogCategorySkeleton from "./Skeleton/App";
import { setShareLink } from "../../Components/ShareModal/reduxSlice";
import ShareModel from "../../Components/ShareModal/App";

import { getSocialAsync } from "../../Components/SocialLinks/reduxSlice";
import { getBlogAsync } from "../Homepage/reduxSlice";
import { getDashboardAsync } from "../../Components/Dashboard/reduxSlice";
import { getCategoryAsync } from "../../Components/CategoryList/reduxSlice";
import SkeletonComponent from "./Skeleton/App";

export default function BlogCategory() {
  const { categoryParam } = useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getSocialAsync({}));
    dispatch(getBlogAsync({}));
    dispatch(getDashboardAsync({}));
    dispatch(getCategoryAsync({}));
  }, [dispatch]);
  const { collection, loading } = useSelector(homepageState);

  const [show, setShow] = React.useState(false);
  const navigate = useNavigate();
  const handleClick = (name, id) => {
    navigate(`/${name}+${id}`);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleShare = (name, id) => {
    const url = window.location;
    setShow(true);
    dispatch(
      setShareLink({
        // http://localhost:3000/sdasedsde+XvCyEcRDIxiHBHupRGis
        value: url.protocol + "//" + url.host + "/" + name + "+" + id,
      })
    );
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      {loading ? (
        <SkeletonComponent />
      ) : (
        <>
          <Navbar />
          <Box sx={{ mx: { md: 10, xs: 0 }, mt: 8, mb: 8 }}>
            <Typography
              variant="h5"
              component="div"
              className="fw-semibold"
              sx={{
                color: "#fff",
                background: "var(--color-black)",
                py: 2,
                mb: 3,
                textAlign: "center",
              }}
            >
              {categoryParam ? categoryParam.toUpperCase() : null}
            </Typography>
            <Box
              sx={{
                bgcolor: "background.paper",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                px: { md: 0.5, xs: 1 },
                mb: 5,
              }}
            >
              {/* Categorywise blog cards */}
              {collection
                ? collection
                    ?.filter(
                      (item) => item.category === categoryParam.toUpperCase()
                    )
                    .map((item, index) => (
                      <>
                        <Card
                          key={index}
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: { sm: "row", xs: "column" },
                            position: "relative",
                          }}
                        >
                          <CardMedia
                            component="img"
                            sx={{
                              width: { sm: 250, xs: "100%" },
                              height: { sm: 200, xs: "100%" },
                              objectFit: "fill",
                            }}
                            image={item.section[0]?.img.Location}
                            alt="post"
                          />
                          <CardContent
                            className="multiLineLabel"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              p: 2,
                              // position: "relative",
                            }}
                          >
                            <Typography
                              component="div"
                              className="textMaxLine fw-semibold"
                              variant="h5"
                              color="var(--color-blue)"
                              sx={{ width: "95%", cursor: "pointer" }}
                              onClick={() => {
                                const name = item.section[0]?.heading;
                                const id = item.id;
                                handleClick(name, id);
                              }}
                            >
                              {item.section[0]?.heading}
                            </Typography>
                            <Typography
                              className="textMaxLineThree pt-2"
                              variant="body1"
                              component="div"
                              sx={{ cursor: "pointer", mb: { md: 0, xs: 1.5 } }}
                              onClick={() => {
                                const name = item.section[0]?.heading;
                                const id = item.id;
                                handleClick(name, id);
                              }}
                            >
                              {parse(item.section[0]?.body)}
                            </Typography>

                            <Typography
                              variant="body1"
                              component="p"
                              sx={{
                                position: "absolute",
                                right: 10,
                                bottom: { sm: 0, xs: -3 },
                                p: 1,
                                fontSize: { md: "1rem", xs: "0.7rem" },
                              }}
                            >
                              {moment(item.selectedDate).format("DD MMM YYYY")}
                            </Typography>
                          </CardContent>
                          <IconButton
                            size="small"
                            sx={{
                              position: "absolute",
                              top: 5,
                              right: 5,
                              color: "black",
                            }}
                            onClick={() => {
                              const name = item.section[0]?.heading;
                              const id = item.id;
                              handleShare(name, id);
                            }}
                          >
                            <ShareIcon />
                          </IconButton>
                        </Card>
                      </>
                    ))
                : null}
            </Box>
          </Box>
          <FooterComponent className="mt-4" />
          {/* <ShareModel show={show} handleClose={handleClose} /> */}
        </>
      )}
    </>
  );
}
