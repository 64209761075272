import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getProfile,updateProfile } from "./reduxAPI";
const initialState = {
  username: "",
  name: "",
  surname: "",
  loading: false,
  collection: "",
};

export const getProfileAsync = createAsyncThunk(
  "profile/getProfile",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const token = window.sessionStorage.getItem("token");
      const response = await getProfile(token);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const updateProfileAsync = createAsyncThunk(
  "profile/updateProfile",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const token = window.sessionStorage.getItem("token");
      const { username, name, surname } = getState().profile;
      const response = await updateProfile( token,username, name, surname  );
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload.value;
    },
    setName: (state, action) => {
      state.name = action.payload.value;
    },
    setSurname: (state, action) => {
      state.surname = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProfileAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.collection = action.payload.data;
        if (state.collection) {
          state.name = state.collection.firstName;
          state.surname = state.collection.lastName;
          state.username = state.collection.userName;
        }
      })
      .addCase(getProfileAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateProfileAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateProfileAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateProfileAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setUsername, setName, setSurname } = profileSlice.actions;
export const profileState = (state) => state.profile;
export default profileSlice.reducer;
