import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";

export function postDashboardAPI(companyName, logo, email) {
  const postDashboard = httpsCallable(functions, "postDashboard");
  return postDashboard({ companyName, logo, email });
}

export function getDashboardAPI(token) {
  const getDashboard = httpsCallable(functions, "getDashboard");
  return getDashboard({ token });
}