import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postDashboardAPI, getDashboardAPI } from "./reduxAPI";

const initialState = {
  dashboardTab: "Dashboard",
  companyName: "",
  logo: "",
  email: "",
  loading: false,
  collection: "",
};

export const postDashboardAsync = createAsyncThunk(
  "dashboard/postDashboard",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { companyName, logo, email } = getState().dashboard;
      const response = await postDashboardAPI(companyName, logo, email);
      dispatch(clearState);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getDashboardAsync = createAsyncThunk(
  "dashboard/getDashboard",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const token = window.sessionStorage.getItem("token");
      const response = await getDashboardAPI(token);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardTab: (state, action) => {
      state.dashboardTab = action.payload.dashboardTab;
    },
    setCompanyName: (state, action) => {
      state.companyName = action.payload.value;
    },
    setLogo: (state, action) => {
      state.logo = action.payload.value;
    },
    setEmail: (state, action) => {
      state.email = action.payload.value;
    },
    clearState: (state) => {
      state.companyName = "";
      state.logo = "";
      state.email = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postDashboardAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(postDashboardAsync.fulfilled, (state, action) => {
        state.loading = false;
        // state.collection = action.payload
      })
      .addCase(postDashboardAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getDashboardAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getDashboardAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.collection = action.payload.data;
      })
      .addCase(getDashboardAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  setDashboardTab,
  setCompanyName,
  setLogo,
  setEmail,
  clearState,
} = dashboardSlice.actions;
export const dashboardState = (state) => state.dashboard;
export default dashboardSlice.reducer;
