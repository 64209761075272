import { Grid, TextField, Button, Container } from "@mui/material";
import * as React from "react";
import Typography from "@mui/material/Typography";
import SaveIcon from "@mui/icons-material/Save";
import InputAdornment from "@mui/material/InputAdornment";
import ModeIcon from "@mui/icons-material/Mode";
import PersonIcon from "@mui/icons-material/Person";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  setUsername,
  setName,
  setSurname,
  getProfileAsync,
  profileState,
  updateProfileAsync,
} from "./reduxSlice";
import Navbarcomponent from '../Navbar/Navbar';
import FooterComponent from "../Footer/Footer";
import { useLocation } from "react-router-dom";

const Profile = (props) => {
  const location = useLocation();
  // console.log(location.pathname)
  const dispatch = useDispatch();
  const { username, name, surname, loading } = useSelector(profileState);
  React.useEffect(() => {
    dispatch(getProfileAsync({}));
  }, [dispatch]);
  return (
    <>
    
      <Container maxWidth="lg" sx={{mt:2, mb:4}}>
        <Grid container spacing={{lg:4, sm:2, xs:2}} sx={{ p: 1, alignItems: "center" }}>
          <Grid item xs={12}>
            <Typography variant="h4" component="h4">
              Update Profile
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="User name"
              defaultValue={username ? username : null}
              placeholder="User name"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ModeIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => dispatch(setUsername({ value: e.target.value }))}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ width: "100%" }}>
            <TextField
              fullWidth
              id="Name"
              label="Name"
              defaultValue={name ? name : null}
              placeholder="Name"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon color="action" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => dispatch(setName({ value: e.target.value }))}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ width: "100%" }}>
            <TextField
              fullWidth
              id="Surname"
              label="Surname"
              defaultValue={surname ? surname : null}
              placeholder="Surname"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon color="action" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => dispatch(setSurname({ value: e.target.value }))}
            />
          </Grid>
          {location.pathname === '/dashboard' ? (
            <Button
            variant="contained"
            startIcon={<SaveIcon />}
            sx={{ ml: 4, my: 2 }}
            onClick={() => { dispatch(updateProfileAsync({}))}} >
            {username && name && surname ? "update" : "save"}
          </Button>
          ) 
          :
          (
            <Button
            variant="contained"
            startIcon={<SaveIcon />}
            sx={{ ml: 4, my: 2 }}
            onClick={() => { dispatch(updateProfileAsync({}));
            props.closeModal()}} >
            {username && name && surname ? "update" : "save"}
          </Button>
          )}
          
        </Grid>
      </Container>
      
    </>
  );
};

export default Profile;
