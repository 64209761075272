import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";

export function postCategoryAPI(category) {
  const postCategory = httpsCallable(functions, "postCategory");
  return postCategory({ category });
}

export function updateCategoryAPI(pass, category) {
  const updateCategory = httpsCallable(functions, "updateCategory");
  return updateCategory({ pass, category });
}

export function getCategoryAPI(token) {
  const getCategory = httpsCallable(functions, "getCategory");
  return getCategory({ token });
}
export function deleteCategoryAPI(pass) {
  const deleteCategory = httpsCallable(functions, "deleteCategory");
  return deleteCategory({ pass });
}
