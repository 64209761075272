import { createSlice } from "@reduxjs/toolkit";
import postsData from "./output.json"

const initialState = {
    posts: postsData
}

const postsSlice = createSlice({
    name: "posts",
    initialState,
    reducers: {}
})

export default postsSlice.reducer
export const postsState = (state) => state.posts