import {
  Box,
  Grid,
  Typography,
  IconButton,
  Button,
  Container,
  Alert,
} from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import FooterComponent from "../../Components/Footer/Footer";
import submitForm from "./ContactForm";

import { getSocialAsync } from "../../Components/SocialLinks/reduxSlice";
import { getBlogAsync } from "../Homepage/reduxSlice";
import { getDashboardAsync } from "../../Components/Dashboard/reduxSlice";
import { getCategoryAsync } from "../../Components/CategoryList/reduxSlice";
import { useDispatch } from "react-redux";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const Contact = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleHomepageClick = (event) => {
    navigate("/");
  };
  React.useEffect(() => {
    dispatch(getSocialAsync({}));
    dispatch(getBlogAsync({}));
    dispatch(getDashboardAsync({}));
    dispatch(getCategoryAsync({}));
  }, [dispatch]);

  // handle form data
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    submitForm(
      data.get("fName"),
      data.get("lName"),
      data.get("Email"),
      data.get("phone"),
      data.get("company"),
      data.get("city"),
      data.get("message")
    );
  };
  return (
    <>
    {/* <Box sx={{height:{lg:'100vh'}}}> */}
      <Navbar />
      <Container maxWidth="xl" sx={{my:4}}>
        <Grid container sx={{ ml: 0, pl: 0 }}>
          <Grid item xs={12} md={6} sx={{ p: { xs: 1, sm: 4 },  }}>
            <Typography
              variant="h3"
              onClick={handleHomepageClick}
              component="h3"
              sx={{ color: "#f87330", mt: 8, cursor: "pointer" }}
              className="fw-semibold"
            >
              Biz Blogs
            </Typography>
            <Typography
              variant="subtitle2"
              component="p"
              className="fw-semibold"
              sx={{ mt: 2 }}
            >
              With the use of advanced and entrancing features, we design a
              virtual space, where it allows one to host and create virtual and
              Hybrid Events, Webinars, Online Conferences, Trade Shows, Job
              Fairs, Showrooms, Exhibitions, Offices, and much more with just a
              few clicks.
            </Typography>
            <Typography
              variant="subtitle2"
              component="p"
              className="fw-semibold"
              sx={{ mt: 2 }}
            >
              If you are interested in knowing more about our work, talk to us
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mt: 3, gap: 2 }}>
              <IconButton size="large" className="p-0 m-0">
                <EmailIcon fontSize="inherit" sx={{ color: "#f87330" }} />
              </IconButton>
              <Box>
                <Typography
                  variant="subtitle2"
                  component="div"
                  className="fw-bold"
                >
                  Email us at
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  className="fw-semibold"
                >
                  Contact @bizconnectevents.com
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="subtitle2"
              component="div"
              className="fw-bold"
              sx={{ mt: 2, pr: 1 }}
            >
              Contact us on:
              <IconButton
                size="large"
                className="p-1 m-0"
                href="https://www.facebook.com/bizconnectevents.india"
                target="_blank"
              >
                <FacebookIcon fontSize="inherit" sx={{ color: "#f87330" }} />
              </IconButton>
              <IconButton
                size="large"
                className="p-1 m-0"
                href="https://www.linkedin.com/company/bizconnect-pvt-ltd/mycompany/"
                target="_blank"
              >
                <LinkedInIcon fontSize="inherit" sx={{ color: "#f87330" }} />
              </IconButton>
              <IconButton
                size="large"
                className="p-1 m-0"
                href="https://www.instagram.com/bizconnect_global_events/"
                target="_blank"
              >
                <InstagramIcon fontSize="inherit" sx={{ color: "#f87330" }} />
              </IconButton>
              <IconButton
                size="large"
                className="p-1 m-0"
                href="https://twitter.com/BizConnectEven1"
                target="_blank"
              >
                <TwitterIcon fontSize="inherit" sx={{ color: "#f87330" }} />
              </IconButton>
              <IconButton
                size="large"
                className="p-1 m-0"
                href="https://www.youtube.com/channel/UCGj27x3_ShNbpu97zMDiYlQ"
                target="_blank"
              >
                <YouTubeIcon fontSize="inherit" sx={{ color: "#f87330" }} />
              </IconButton>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ p: { sm: 4, xs: 1 }}}>
            <form id="contact-form" onSubmit={handleSubmit}>
              <Grid
                container disableGutters
                spacing={{md:2, xs:1}}
                className="flex"
                sx={{ ml: 0, p: 1, textAlign: "center", width: "100%" }}
              >
                <Grid item xs={12} pl="0">
                  <Alert severity="success" color="info" id="alert">
                    Thank you, we have received your message !
                  </Alert>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ fontWeight: "bold", color: "#0b4995", mt: 2 }}
                  >
                    Contact us
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <InputBase
                      fullWidth
                      type="text"
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="First Name"
                      id="fName"
                      name="fName"
                    />
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <InputBase
                      fullWidth
                      type="text"
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Last Name"
                      id="lName"
                      name="lName"
                    />
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  <Item>
                    <InputBase
                      fullWidth
                      type="email"
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Email Adress *"
                      id="Email"
                      name="Email"
                      required
                    />
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  <Item>
                    <InputBase
                      fullWidth
                      type="tel"
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Phone Number"
                      id="phone"
                      name="phone"
                    />
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  <Item>
                    <InputBase
                      fullWidth
                      type="text"
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Company Name *"
                      id="company"
                      name="company"
                      required
                    />
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  <Item>
                    <InputBase
                      fullWidth
                      type="text"
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Your City"
                      id="city"
                      name="city"
                    />
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  <Item>
                    <InputBase
                      fullWidth
                      type="text"
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Message *"
                      multiline
                      rows={5}
                      id="message"
                      name="message"
                      required
                    />
                  </Item>
                </Grid>
                <Grid xs={12} sx={{ pl: {md:2, xs:1}, mt: 2 }}>
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: "#f87330",
                      width: "100%",
                      color: "white",
                      fontWeight: "bold",
                      ":hover": { backgroundColor: "#ffa500de" },
                    }}
                    onClick={(e) => {
                      window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    SEND
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
      <FooterComponent />
      {/* </Box> */}
    </>
  );
};

export default Contact;
