import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  registerUser,
  loginUser,
  assignRole,
  getUserProfile,
  updateUserProfile,
  getIdTokens,
  signOutAPI,
} from "./reduxAPI";

const initialState = {
  email: "",
  password: "",
  rememberMe: true,
  loading: false,
  firstName: "",
  lastName: "",
};

export const registerUserAsync = createAsyncThunk(
  "auth/registerUser",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { email, password } = getState().auth;
      const response = await registerUser(email, password);
      dispatch(assignRoleAsync({ navigate }));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const assignRoleAsync = createAsyncThunk(
  "auth/assignRole",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { email, firstName, lastName } = getState().auth;
      const response = await assignRole(email, firstName, lastName);
      dispatch(loginUserAsync({ navigate }));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const loginUserAsync = createAsyncThunk(
  "auth/loginUser",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { email, password } = getState().auth;
      const response = await loginUser(email, password);
      window.sessionStorage.setItem("token", response.user.uid);
      dispatch(getClaimsAsync({}));
      navigate("/");
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const singOutAsync = createAsyncThunk(
  "auth/signOutAPI",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { email, password } = getState().auth;
      const response = await signOutAPI();
      window.sessionStorage.clear();
      navigate("/");
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getClaimsAsync = createAsyncThunk(
  "auth/getIdTokens",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { _ } = getState().auth;
      const response = await getIdTokens();
      if (response.claims.superAdmin) {
        window.sessionStorage.setItem("claim", "superAdmin");
      } else if (response.claims.bizAdmin) {
        window.sessionStorage.setItem("claim", "bizAdmin");
      } else {
        window.sessionStorage.setItem("claim", "user");
      }
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload.value;
    },
    setPassword: (state, action) => {
      state.password = action.payload.value;
    },
    setRememberMe: (state, action) => {
      state.rememberMe = !state.rememberMe;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload.value;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(assignRoleAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(assignRoleAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(assignRoleAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(singOutAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(singOutAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(singOutAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  setEmail,
  setPassword,
  setRememberMe,
  setFirstName,
  setLastName,
} = authSlice.actions;
export const authState = (state) => state.auth;
export default authSlice.reducer;
