import React from "react";

// Bootstrap
import { Button, CloseButton, Modal } from "react-bootstrap";

// React Share
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

// Icons
import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// Redux
import { useSelector } from "react-redux";
import { shareState } from "./reduxSlice";
// import { LinkPreview } from "@dhaiwat10/react-link-preview";

const ShareModel = ({ show, handleClose, index }) => {
  //Copy to clipBoard

  const { link, heading } = useSelector(shareState);
  const [copyState, setCopyState] = React.useState(<ContentCopyIcon />);
  const copyRef = React.useRef(null);

  //Copy to clickBoard
  const copyToClipboard = () => {
    if (copyRef.current) {
      copyRef.current.focus(); // 1
      copyRef.current.select(); // 2

      try {
        const wasSuccessful = document.execCommand("copy"); // 3
        setCopyState(wasSuccessful ? <CheckIcon /> : <ContentCopyIcon />);
      } catch (error) {
        console.log(error);
      }
      copyRef.current.blur(); // 5
    }
  };
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="bg-white text-dark ">
          <Modal.Title>Share</Modal.Title>
          <CloseButton onClick={handleClose} className="shadow-none" />
        </Modal.Header>
        <Modal.Body className="bg-white text-dark rounded">
          <h6>Share this link via</h6>
          <ul className="list-unstyled d-inline-flex ">
            <li className="m-2">
              <FacebookShareButton
                url={link}
                quote={heading}
                hashtag="#OnlineBizconnectEvent"
              >
                <FacebookIcon size={36} />
              </FacebookShareButton>
            </li>
            <li className="m-2">
              <TelegramShareButton
                url={link}
                quote={heading}
                hashtag="#OnlineBizconnectEvent"
              >
                <TelegramIcon size={36} />
              </TelegramShareButton>
            </li>
            <li className="m-2">
              <TwitterShareButton
                url={link}
                quote={heading}
                hashtag="#OnlineBizconnectEvent"
              >
                <TwitterIcon size={36} />
              </TwitterShareButton>
            </li>
            <li className="m-2">
              <LinkedinShareButton
                url={link}
                quote={heading}
                hashtag="#OnlineBizconnectEvent"
              >
                <LinkedinIcon size={38} />
              </LinkedinShareButton>
            </li>
            <li className="m-2">
              <WhatsappShareButton url={link} title={heading}>
                <WhatsappIcon size={36} />
              </WhatsappShareButton>
            </li>
            <li className="m-2">
              <EmailShareButton
                url={link}
                quote={heading}
                hashtag="#OnlineBizconnectEvent"
              >
                <EmailIcon size={36} />
              </EmailShareButton>
            </li>
          </ul>

          <div className="row">
            <div className="col">
              <div className="form-group">
                <div className="input-icon">
                  <h6>Copy link</h6>
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        ref={copyRef}
                        className="form-control bg-light text-dark border"
                        value={heading + " " + link}
                        readOnly
                        placeholder="Some path"
                        id="copy-input"
                      />
                      <span className="input-group-btn">
                        <Button
                          className="bg-light text-dark border"
                          onClick={copyToClipboard}
                        >
                          {copyState}
                        </Button>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ShareModel;
