import * as React from "react";
import InputBase from "@mui/material/InputBase";
import {
  Typography,
  Box,
  Container,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import PublicIcon from "@mui/icons-material/Public";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { singOutAsync } from "../Auth/reduxSlice";
import { useDispatch, useSelector } from "react-redux";
import { socialLinkState } from "../SocialLinks/reduxSlice";
import { dashboardState } from "../Dashboard/reduxSlice";
import { categoryState } from "../CategoryList/reduxSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProfileComponent from "../Profile/Profile";

const lang = [
  { value: "En", label: "En" },
  { value: "Hi", label: "Hi" },
];

const Search = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing("5px", "12px", "5px", "0px"),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      width: "8ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", md: "50%" },
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { categoryCollection } = useSelector(categoryState);
  const { youtube, facebook, instagram, twitter, linkedin } =
    useSelector(socialLinkState);
  const { collection } = useSelector(dashboardState);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const claim = window.sessionStorage.getItem("claim");
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openLoginLogout = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeLoginLogout = () => {
    setAnchorEl(null);
  };

  const [modalOpen, setModalOpen] = React.useState(false);

  const [anchorBlogCategoryEl, setAnchorBlogCategoryEl] = React.useState(null);
  const openBlogCategory = Boolean(anchorBlogCategoryEl);
  const handleBlogCategoryClick = (event) => {
    setAnchorBlogCategoryEl(event.currentTarget);
  };
  const handleBlogCategoryClose = () => {
    setAnchorBlogCategoryEl(null);
  };

  const handleHomepageClick = (event) => {
    navigate("/");
  };

  const handleSigninClick = (event) => {
    navigate("/signin");
  };

  const handleDashboardClick = (event) => {
    navigate("/dashboard");
  };
  // const handleProfileClick = (event) => {
  //   navigate("/profile");
  // };
  const navigateBlogCategory = (event) => {
    navigate("/blog-category");
  };
  const handleAboutClick = (event) => {
    navigate("/aboutus");
  };
  const handleContactClick = (event) => {
    navigate("/contact");
  };

  return (
    <>
      {/* appbar having menuicon and navigation pages */}
      <Container maxWidth="fluid" disableGutters>
        <AppBar sx={{ background: "var(--color-black)" }} position="static">
          <Toolbar
            disableGutters
            sx={{ justifyContent: "space-between", px: 2 }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: "none", md: "block" }, py: { md: 2 } }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",

                  // my: { md: "1rem", xs: "0.5rem" },
                }}
              >
                {collection ? (
                  <img
                    src={collection?.logo?.Location}
                    height={60}
                    // style={{ background: "#" }}
                    alt="logo"
                  />
                ) : null}

                <Button
                  onClick={handleHomepageClick}
                  sx={{ color: "#fff", fontWeight: "700", ml: 1 }}
                >
                  HOME
                </Button>
                <Button
                  onClick={handleBlogCategoryClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  sx={{ color: "#fff", fontWeight: "700" }}
                >
                  BLOG CATEGORY
                </Button>
                <Menu
                  anchorEl={anchorBlogCategoryEl}
                  open={openBlogCategory}
                  onClose={handleBlogCategoryClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{
                    "& .MuiPaper-root": {
                      boxShadow: 0.2,
                    },
                  }}
                >
                  {categoryCollection
                    ? categoryCollection?.map((item, index) => (
                        <MenuItem
                          onClick={() => {
                            navigate(`/Category/${item.category}`);
                            handleBlogCategoryClose();
                          }}
                          key={index}
                          sx={{ color: "black" }}
                        >
                          {item.category}
                        </MenuItem>
                      ))
                    : null}
                </Menu>
                <Button
                  onClick={handleAboutClick}
                  sx={{ color: "#fff", fontWeight: "700" }}
                >
                  ABOUT US
                </Button>
                <Button
                  onClick={handleContactClick}
                  sx={{ color: "#fff", fontWeight: "700" }}
                >
                  CONTACT US
                </Button>
              </Box>
            </Box>

            {/* search box here and dashboard menu */}
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box sx={{ flexGrow: 0 }}>
                <Search
                  sx={{
                    position: "relative",
                    borderRadius: "60px",
                    backgroundColor: "white",
                    color: "black",
                    marginLeft: 0,
                    width: "100%",
                  }}
                >
                  <SearchIconWrapper
                    className="flex"
                    sx={{
                      height: "100%",
                      position: "absolute",
                      pointerEvents: "none",
                    }}
                  >
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
              </Box>

              {/* language selector */}
              <TextField
                select
                variant="standard"
                defaultValue="En"
                sx={{
                  display: "none",
                  width: {
                    xs: "auto",
                    md: "80px",
                    "& .MuiInput-underline:after": { borderBottom: "0" },
                  },
                  color: "black",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PublicIcon sx={{ color: "#FFF" }} />
                    </InputAdornment>
                  ),
                  style: {
                    color: "#FFF",
                  },
                }}
              >
                {lang.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{ color: "black" }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              {/* login/logout */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Signin or signup">
                  <IconButton
                    onClick={openLoginLogout}
                    size="small"
                    aria-controls={open ? "login-logout" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <AccountCircleIcon sx={{ color: "white" }} />
                    {/* <Avatar sx={{ width: 32, height: 32 }} src="../images/girl.jpg"></Avatar> */}
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="login-logout"
                open={open}
                onClose={closeLoginLogout}
                onClick={closeLoginLogout}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {claim ? (
                  <>
                    <MenuItem
                      onClick={() => {
                        setModalOpen(true);
                      }}
                      sx={{ color: "black" }}
                    >
                      Profile
                    </MenuItem>
                  </>
                ) : null}
                {claim ? (
                  <MenuItem
                    sx={{ color: "black" }}
                    onClick={() => {
                      dispatch(singOutAsync({}));
                      window.location.reload();
                    }}
                  >
                    Sign Out
                  </MenuItem>
                ) : (
                  <MenuItem onClick={handleSigninClick} sx={{ color: "black" }}>
                    Signin / Signup
                  </MenuItem>
                )}
                {claim === "superAdmin" || claim === "bizAdmin" ? (
                  <MenuItem
                    onClick={handleDashboardClick}
                    sx={{ color: "black" }}
                  >
                    Dashboard
                  </MenuItem>
                ) : null}
                <MenuItem onClick={handleAboutClick} sx={{ color: "black" }}>
                  About
                </MenuItem>
                <MenuItem onClick={handleContactClick} sx={{ color: "black" }}>
                  Contact
                </MenuItem>
              </Menu>
              <Modal
                open={modalOpen}
                onClose={() => {
                  setModalOpen(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => {
                        setModalOpen(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <ProfileComponent closeModal={() => setModalOpen(false)} />
                </Box>
              </Modal>
            </Box>
          </Toolbar>
        </AppBar>

        <Box component="nav">
          <Drawer
            // container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "60%", sm: "40%" },
              },
            }}
          >
            <Box
              // onClick={handleDrawerToggle}
              sx={{ textAlign: "center" }}
            >
              <Typography variant="h6" sx={{ my: 2 }}>
                BIZ BLOGS
              </Typography>
              <Divider />
              <List sx={{ alignItems: "center" }}>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={handleHomepageClick}
                    sx={{ textAlign: "center" }}
                  >
                    <ListItemText
                      secondary="HOME"
                      sx={{ color: "black", fontWeight: "bold" }}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding disableGutters>
                  <ListItemButton
                    disableRipple
                    // onClick={handleBlogCategoryClick}
                    sx={{ py: 0, display: "flex", justifyContent: "center" }}
                  >
                    {/* <ListItemText
                      secondary="BLOG CATEGORY"
                      sx={{ color: "black", fontWeight: "bold" }}
                    /> */}
                    <ListItemText>
                      <Accordion
                        disableGutters
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                        sx={{
                          boxShadow: "none",
                          width: "100%",
                          px: { sm: 2, xs: 0 },
                          ml: { sm: 1.5, xs: 1 },
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <AccordionSummary
                          sx={{ p: 0 }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <Typography
                            variant="body2"
                            component="p"
                            sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                          >
                            BLOG CATEGORY
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            py: 0,
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          {categoryCollection
                            ? categoryCollection?.map((item, index) => (
                                <MenuItem
                                  onClick={() => {
                                    navigate(`/Category/${item.category}`);
                                    handleBlogCategoryClose();
                                    setExpanded(false);
                                    handleDrawerToggle();
                                  }}
                                  key={index}
                                  sx={{ color: "rgba(0, 0, 0, 0.6)", pr: 4 }}
                                >
                                  {item.category}
                                </MenuItem>
                              ))
                            : null}
                        </AccordionDetails>
                      </Accordion>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={handleAboutClick}
                    sx={{ textAlign: "center" }}
                  >
                    <ListItemText
                      secondary="ABOUT US"
                      sx={{ color: "black", fontWeight: "bold" }}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={handleContactClick}
                    sx={{ textAlign: "center" }}
                  >
                    <ListItemText
                      secondary="CONTACT US"
                      sx={{ color: "black", fontWeight: "bold" }}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </Box>

        <Stack
          direction="column"
          spacing={1}
          sx={{
            position: "fixed",
            zIndex: 999,
            right: 10,
            bottom: 10,
            "& .MuiIconButton-root": {
              bgcolor: "var(--color-blue) !important",
              color: "#fff",
              "&:hover": {
                color: "black",
              },
            },
          }}
        >
          {facebook && (
            <IconButton size="small" href={facebook} target="_blank">
              <FacebookOutlinedIcon />
            </IconButton>
          )}
          {youtube && (
            <IconButton size="small" href={youtube} target="_blank">
              <YouTubeIcon />
            </IconButton>
          )}
          {twitter && (
            <IconButton size="small" href={twitter} target="_blank">
              <TwitterIcon />
            </IconButton>
          )}
          {linkedin && (
            <IconButton size="small" href={linkedin} target="_blank">
              <LinkedInIcon />
            </IconButton>
          )}
          {instagram && (
            <IconButton size="small" href={instagram} target="_blank">
              <InstagramIcon />
            </IconButton>
          )}
        </Stack>
      </Container>
    </>
  );
}
export default Navbar;
