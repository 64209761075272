import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";

export function getProfile(token) {
  const getUserProfile = httpsCallable(functions, "getUserProfile");
  return getUserProfile({ token });
}
export function updateProfile( token,username, name, surname ) {
  const updateUserProfile = httpsCallable(functions, "updateUserProfile");
  return updateUserProfile({  token,username, name, surname  });
}
