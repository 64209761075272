import {
  Box,
  Paper,
  Typography,
  Pagination,
  Container,
  Button,
  TextField,
  Card,
  CardContent,
} from "@mui/material";
import * as React from "react";
import ShareIcon from "@mui/icons-material/Share";
import { setShareLink } from "../../Components/ShareModal/reduxSlice";
import { useSelector } from "react-redux";
import { useState } from "react";
import Comment from "../Comments/Comment";
import { popularBlogState } from "../PopularList/reduxSlice";
import parse from "html-react-parser";
import { homepageState } from "../../Pages/Homepage/reduxSlice";
import { useNavigate } from "react-router-dom";
import ShareModel from "../../Components/ShareModal/App";
import { useDispatch } from "react-redux";
import { getPopularBlogAsync } from "../PopularList/reduxSlice";

const Section2 = () => {
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const { popularCollection } = useSelector(popularBlogState);
  const { collection } = useSelector(homepageState);
  const sort = popularCollection?.slice(1, 11);
  const [currentPage, setCurrentPage] = useState(1);
  const postPerPage = 2;

  const indexOfLastPage = currentPage * postPerPage;
  const indexOFFirstPost = indexOfLastPage - postPerPage;
  const currentPost = sort?.slice(indexOFFirstPost, indexOfLastPage);
  let count = Math.ceil(sort.length / postPerPage);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const [message, setMessage] = useState("");

  React.useEffect(() => {
    dispatch(getPopularBlogAsync({}));
  }, [dispatch]);

  const clearInput = (event) => {
    setMessage("");
  };

  const navigate = useNavigate();

  const handleClick = (name, id) => {
    navigate(`/${name}+${id}`);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleShare = (name, id) => {
    const url = window.location;
    setShow(true);
    dispatch(
      setShareLink({
        // http://localhost:3000/sdasedsde+XvCyEcRDIxiHBHupRGis
        value: url.protocol + "//" + url.host + "/" + name + "+" + id,
      })
    );
  };
  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ my: 3, mb: 10, p: { xs: 0, sm: 2 } }}>
        <Typography
          variant="h5"
          component="div"
          className="fw-semibold"
          sx={{
            color: "#fff",
            background: "var(--color-black)",
            py: 2,
            pl: 3,
            mb: 3,
          }}
        >
          POPULAR POSTS
        </Typography>
        <Box
          sx={{
            my: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: {
              xs: "column",
              lg: "row",
            },
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: {
                xs: "100%",
                lg: "68%",
              },
            }}
          >
            {/* HERO SECTION START */}
            {popularCollection
              ? popularCollection?.slice(0, 1)?.map((item, index) => (
                  <Paper sx={{ p: "0.8rem" }}>
                    <Box
                      sx={{
                        height: "100%",
                        width: "100%",
                        pt: {
                          lg: 0,
                          xs: 20,
                          md: 12,
                        },
                        display: "flex",
                        backgroundImage: `linear-gradient(45deg, rgba(19,19,28,0.5) 0%, rgba(15,16,24,.9) 100%), url("${item.section[0]?.img.Location}")`,
                        backgroundPosition: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        position: "relative",
                      }}
                    >
                      <Box
                        className="multiLineLabel"
                        sx={{
                          p: { md: 2, xs: 1 },
                          width: "100%",
                          cursor: "pointer",
                          mt: "auto",
                        }}
                      >
                        <Typography
                          className="fw-semibold textMaxLine"
                          variant="h5"
                          component="h5"
                          color="var(--color-blue)"
                          sx={{
                            mt: {
                              lg: "20rem",
                              md: "15rem",
                              sm: "6rem",
                              xs: "2rem",
                            },
                          }}
                          onClick={() => {
                            const name = item.section[0]?.heading;
                            const id = item.id;
                            handleClick(name, id);
                          }}
                        >
                          {item.section[0]?.heading}
                        </Typography>
                        <Typography
                          className="textMaxLineTwo"
                          variant="subtitle2"
                          component="div"
                          sx={{
                            my: { md: "0.5rem", xs: "0.5rem" },
                            color: "#fff",
                          }}
                          onClick={() => {
                            const name = item.section[0]?.heading;
                            const id = item.id;
                            handleClick(name, id);
                          }}
                        >
                          {parse(item.section[0]?.body)}
                        </Typography>
                        <Comment
                          section2Date={item.selectedDate}
                          section2Name={item.auth}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          cursor: "pointer",
                          color: "white",
                          position: "absolute",
                          borderRadius: "4px",
                          top: 15,
                          right: 20,
                        }}
                      >
                        <ShareIcon
                          onClick={() => {
                            const name = item.section[0]?.heading;
                            const id = item.id;
                            handleShare(name, id);
                          }}
                        />
                      </Box>
                    </Box>
                  </Paper>
                ))
              : null}
            {/* SECTION BELOW HERO START */}
            {popularCollection[1] ? (
              <Paper
                sx={{
                  width: "100%",
                  height: "100%",
                  mt: "2rem",
                  p: "1rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: {
                      md: "row",
                      xs: "column",
                    },
                    gap: { lg: 4, sm: 2, xs: 2 },
                  }}
                >
                  {popularCollection
                    ? currentPost?.map((item, index) => {
                        return (
                          <Card
                            key={index}
                            className=" w-100 h-100 d-flex flex-column"
                            sx={{
                              position: "relative",
                              alignSelf: "normal",
                              boxShadow: "none",
                              maxWidth: {
                                xs: "100%",
                                md: "calc(100% / 2)",
                              },
                            }}
                          >
                            <Box
                              component="div"
                              sx={{
                                aspectRatio: "16/9",
                                backgroundImage: `linear-gradient(0deg,rgba(0, 0, 0, 0.452) 0,#1e1e1e3d 42%,rgb(0 0 0 / 13%)), url("${item.section[0]?.img.Location}")`,
                                backgroundPosition: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                              }}
                              alt=".."
                            />
                            <CardContent
                              className="multiLineLabel"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                                cursor: "pointer",
                                backgroundColor: "#FFFFFF",
                              }}
                            >
                              <Typography
                                component="h5"
                                variant="h5"
                                className="textMaxLine fw-semibold my-2"
                                color="var(--color-blue)"
                                onClick={() => {
                                  const name = item.section[0]?.heading;
                                  const id = item.id;
                                  handleClick(name, id);
                                }}
                              >
                                {item.section[0]?.heading}
                              </Typography>
                              <Comment
                                popularPostName={item.auth}
                                popularPostDate={item.selectedDate}
                              />
                              <Typography
                                variant="body1"
                                component="div"
                                className="textMaxLineThree mt-1"
                                onClick={() => {
                                  const name = item.section[0]?.heading;
                                  const id = item.id;
                                  handleClick(name, id);
                                }}
                                sx={{
                                  "& h5": {
                                    fontSize: "15px !important",
                                  },
                                }}
                              >
                                {parse(item.section[0]?.body)}
                              </Typography>
                            </CardContent>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                cursor: "pointer",
                                top: 15,
                                right: 15,
                                gap: 1,
                                position: "absolute",
                                color: "white",
                                float: "right",
                                borderRadius: "4px",
                              }}
                            >
                              <ShareIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  const name = item.section[0]?.heading;
                                  const id = item.id;
                                  handleShare(name, id);
                                }}
                              />
                            </Box>
                          </Card>
                        );
                      })
                    : null}
                </Box>
                <Pagination
                  onChange={handleChange}
                  count={count}
                  page={currentPage}
                  sx={{
                    maxWidth: "199px",
                    m: "2rem auto 0 auto",
                  }}
                  variant="outlined"
                  shape="rounded"
                  size="small"
                />
              </Paper>
            ) : null}

            {/* SECTION BELOW HERO END */}
          </Box>
          {/* RIGHT HAND SECTION START */}
          <Paper
            sx={{
              width: {
                xs: "100%",
                lg: "34%",
              },
              boxShadow: 2,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              className="fw-semibold"
              sx={{
                background: "var(--color-black)",
                color: "#fff",
                py: 2,
                pl: 2,
              }}
            >
              NEWSLETTER
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              sx={{ m: "20px 0", px: 2, color: "#bfbfbf" }}
            >
              Here, I focus on a range of items and features that we use in life
              without giving them a second thought.
            </Typography>
            <Box className=" px-2">
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                value={message}
                placeholder="Email Address"
                sx={{
                  "	.MuiOutlinedInput-root": { pr: 0 },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "black",
                    },
                  },
                  "& fieldset": {
                    borderRadius: 0,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <Button
                      onClick={clearInput}
                      size="large"
                      variant="contained"
                      sx={{
                        backgroundColor: "var(--color-blue) !important",
                        boxShadow: "none",
                        borderRadius: 0,
                      }}
                    >
                      Subscribe
                    </Button>
                  ),
                }}
              />
            </Box>
            <Typography
              variant="subtitle1"
              component="p"
              px={2}
              sx={{ m: "20px 0", color: "#bfbfbf" }}
            >
              You can unsubscribe us at any time
            </Typography>
            <Typography
              variant="h6"
              component="div"
              className="fw-semibold"
              sx={{
                background: "var(--color-black)",
                color: "#fff",
                py: 2,
                pl: 2,
                mb: 3,
              }}
            >
              PREVIOUS BLOGS
            </Typography>
            {collection.length > 13
              ? collection
                  ?.slice(collection.length - 8, collection.length)
                  .map((item, index) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          my: "0.8rem",
                          gap: 1,
                          m: 1,
                        }}
                        key={index}
                      >
                        <img
                          src={item.section[0]?.img.Location}
                          alt="list_image"
                          style={{
                            width: 75,
                            aspectRatio: 4 / 4,
                            objectFit: "cover",
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                          className="multiLineLabel"
                        >
                          <Typography
                            className="textMaxLine fw-semibold"
                            variant="subtitle1"
                            component="div"
                            color="#000"
                            onClick={() => {
                              const name = item.section[0]?.heading;
                              const id = item.id;
                              handleClick(name, id);
                            }}
                          >
                            {item.section[0]?.heading}
                          </Typography>
                          <Comment
                            prevBlogsName={item.auth}
                            prevBlogsDate={item.selectedDate}
                          />
                        </Box>
                      </Box>
                    );
                  })
              : null}
          </Paper>
          {/* RIGHT HAND SECTION END */}
        </Box>
      </Container>
      <ShareModel show={show} handleClose={handleClose} />
    </>
  );
};

export default Section2;
