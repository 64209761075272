import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSelectedBlogAPI, getSelectedPopularBlogAPI } from "./reduxAPI";
const initialState = {
  paramId: null,
  exploreData: "",
  popularExploreData: "",
  loading: false,
};
export const getSelectedBlogAsync = createAsyncThunk(
  "explorepage/getSelectedBlogAPI",
  async ({ pass }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await getSelectedBlogAPI(pass);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getSelectedPopularBlogAsync = createAsyncThunk(
  "explorepage/getSelectedPopularBlogAPI",
  async ({ pass }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await getSelectedPopularBlogAPI(pass);
      console.log("hiii from explore");
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const explorePageSlice = createSlice({
  name: "explorepage",
  initialState,
  reducers: {
    setParamId: (state, acton) => {
      state.paramId = acton.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSelectedBlogAsync.pending, (state, action) => {
        state.loading = true;
        state.collection = "";
      })
      .addCase(getSelectedBlogAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.exploreData = action.payload.data;
      })
      .addCase(getSelectedBlogAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getSelectedPopularBlogAsync.pending, (state, action) => {
        state.loading = true;
        state.collection = "";
      })
      .addCase(getSelectedPopularBlogAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.popularExploreData = action.payload.data;
      })
      .addCase(getSelectedPopularBlogAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setParamId } = explorePageSlice.actions;
export const explorePageState = (state) => state.explorepage;
export default explorePageSlice.reducer;
