import * as React from "react";
import { Grid, TextField, Button, Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import SaveIcon from "@mui/icons-material/Save";
import InputAdornment from "@mui/material/InputAdornment";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  setFacebook,
  setInstagram,
  setLinkedin,
  setTwitter,
  setYoutube,
  postSocialAsync,
  socialLinkState,
} from "./reduxSlice";
// import { getSocialAsync } from "../../Components/SocialLinks/reduxSlice";

const SocailLinks = () => {
  const dispatch = useDispatch();
  const { collection, loading } = useSelector(socialLinkState);
  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={4} sx={{ p: 2, alignItems: "center" }}>
          <Grid item xs={12}>
            <Typography variant="h4" component="h4">
              Add Social Links
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="Youtube"
              label="Youtube"
              placeholder="Youtube"
              variant="outlined"
              defaultValue={collection ? collection?.youtube : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <YouTubeIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => dispatch(setYoutube({ value: e.target.value }))}
            />
          </Grid>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <TextField
              fullWidth
              id="Facebook"
              label="Facebook"
              placeholder="Facebook"
              variant="outlined"
              defaultValue={collection ? collection?.facebook : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FacebookIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => dispatch(setFacebook({ value: e.target.value }))}
            />
          </Grid>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <TextField
              fullWidth
              id="LinkedIn"
              label="LinkedIn"
              placeholder="LinkedIn"
              variant="outlined"
               defaultValue={collection ? collection?.linkedin : null}
              autoComplete="false"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkedInIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => dispatch(setLinkedin({ value: e.target.value }))}
            />
          </Grid>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <TextField
              fullWidth
              id="twitter"
              label="Twitter"
              placeholder="Twitter"
              variant="outlined"
              autoComplete="false"
              defaultValue={collection ? collection?.twitter : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TwitterIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => dispatch(setTwitter({ value: e.target.value }))}
            />
          </Grid>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <TextField
              fullWidth
              id="Instagram"
              label="Instagram"
              placeholder="Instagram"
              variant="outlined"
              defaultValue={collection ? collection?.instagram : null}
              autoComplete="false"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InstagramIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) =>
                dispatch(setInstagram({ value: e.target.value }))
              }
            />
          </Grid>
          <LoadingButton
            loading={loading}
            loadingIndicator="saved.."
            variant="contained"
            startIcon={<SaveIcon />}
            sx={{ ml: 4, my: 2 }}
            onClick={(e) => {dispatch(postSocialAsync({}))}}
          >
            {collection ? "update" : "save"}
          </LoadingButton>
        </Grid>
      </Container>
    </>
  );
};

export default SocailLinks;
