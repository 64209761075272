import { Button, Divider } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUserAsync, setEmail, setPassword } from "../reduxSlice";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        Home
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(loginUserAsync({navigate}));
  };



  const handleClick = () => {
    navigate("/signup");
  };

  const handleForgot = () => {
    navigate("/forgot");
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
        component="main"
        maxWidth="xs"
      >
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Link underline="none" color="inherit" href="/">
            <Typography
              className="fw-semibold"
              variant="h4"
              component="h4"
              color="#C2B9B0"
            >
              BIZ BLOGS
            </Typography>
          </Link>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              placeholder="Email Address*"
              name="email"
              onChange={(e)=>{dispatch(setEmail({value:e.target.value}))}}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              placeholder="Password*"
              type="password"
              id="password"
              onChange={(e)=>{dispatch(setPassword({value:e.target.value}))}}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  onClick={handleForgot}
                  variant="body2"
                  sx={{ cursor: "pointer" }}
                >
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link
                  onClick={handleClick}
                  variant="body2"
                  sx={{ cursor: "pointer" }}
                >
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", flexDirection: "column", m: "1rem 0 0 0" }}
            >
              <Divider color="primary">or sign in with</Divider>
              <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                <FacebookRoundedIcon
                  sx={{
                    fontSize: "4rem",
                    p: "0.5rem",
                    borderRadius: "50%",
                    bgcolor: "#f4f5f9",
                    color: "#4267B2",
                  }}
                />
                <GoogleIcon
                  color="primary"
                  sx={{
                    mx: "1rem",
                    fontSize: "4rem",
                    p: "0.5rem",
                    borderRadius: "50%",
                    bgcolor: "#f4f5f9",
                  }}
                />
                <LinkedInIcon
                  sx={{
                    fontSize: "4rem",
                    p: "0.5rem",
                    borderRadius: "50%",
                    bgcolor: "#f4f5f9",
                    color: "#0A66C2",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Copyright sx={{ mt: 6, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};

export default SignIn;
