import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBlogAPI } from "./reduxAPI";
const initialState = {
  collection: "",
};

export const getBlogAsync = createAsyncThunk(
  "homepage/getBlogAPI",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const token = window.sessionStorage.getItem("token");
      const response = await getBlogAPI(token);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);


export const homepageSlice = createSlice({
  name: "homepage",
  initialState,
  extraReducers: (builder) => {
    builder

      .addCase(getBlogAsync.pending, (state, action) => {
        state.loading = true;
        state.collection = "";
      })
      .addCase(getBlogAsync.fulfilled, (state, action) => {
        state.loading = false;
        action.payload.data.forEach((item) => {
          state.collection = [...state.collection, item].sort(function (a, b) {
            var c = new Date(a.selectedDate);
            var d = new Date(b.selectedDate);
            return d - c;
          });
        });
      })
      .addCase(getBlogAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { _ } = homepageSlice.actions;
export const homepageState = (state) => state.homepage;
export default homepageSlice.reducer;
