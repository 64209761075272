import * as React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import HeroSectionComponent from "../../Components/HeroSection/HeroSection";
import LatestPage from "../../Components/LatestPage/LatestPage";
// import Latest from "../../Components/Latest/Latest"
import Section2 from "../../Components/Section2/Section2";
import Footer from "../../Components/Footer/Footer";
// import HeroSection from "../../Components/HeroSection/HeroSection";
import { useDispatch, useSelector } from "react-redux";
import SkeletonComponent from "./Skeleton/App";

import { getSocialAsync } from "../../Components/SocialLinks/reduxSlice";
import { getBlogAsync, homepageState } from "./reduxSlice";
import { getDashboardAsync } from "../../Components/Dashboard/reduxSlice";
import { getCategoryAsync } from "../../Components/CategoryList/reduxSlice";

function Homepage() {
  const dispatch = useDispatch();
  const { loading } = useSelector(homepageState);
  React.useEffect(() => {
    dispatch(getSocialAsync({}));
    dispatch(getBlogAsync({}));
    dispatch(getDashboardAsync({}));
    dispatch(getCategoryAsync({}));
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <SkeletonComponent />
      ) : (
        <>
          <Navbar />
          {/* <HeroSection /> */}
          <HeroSectionComponent />
          <LatestPage />
          {/* <Latest/> */}
          <Section2 />
          <Footer />
        </>
      )}
    </>
  );
}
export default Homepage;
