import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequestCommentAPI, approvedComment, deleteComment } from "./reduxAPI";

const initialState = {
  comment: "",
  collection: "",
};

export const getRequestCommentAsync = createAsyncThunk(
  "comment/getRequestCommentAPI",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const token = window.sessionStorage.getItem("token");
      const response = await getRequestCommentAPI(token)
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const approvedCommentAsync = createAsyncThunk(
  "comment/approvedComment",
  async ({ pass }, {dispatch, getState, rejectWithValue}) => {
    try {
      const response = await approvedComment(pass)
      return response
    }
    catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
)



export const commentSlice = createSlice({
  name: "comment",
  initialState,
  reducers: {
    setComment: (state, action) => {state.comment = action.payload.value},
  },
  extraReducers: (builder) => {
    builder
    .addCase(getRequestCommentAsync.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getRequestCommentAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.collection = action.payload
    })
    .addCase(getRequestCommentAsync.rejected, (state, action) => {
      state.loading = false;
    });
  }
});

export const { setComment } = commentSlice.actions;
export const commentState = (state) => state.comment;
export default commentSlice.reducer;