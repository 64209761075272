import { auth, functions } from "../../../firebase/config";
import { httpsCallable } from "firebase/functions";
import { createUserWithEmailAndPassword ,signInWithEmailAndPassword,getIdTokenResult, signOut} from "firebase/auth";

export function registerUser(email, password) {
  return createUserWithEmailAndPassword(auth, email, password);
}

export function loginUser(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}
export function getIdTokens() {
    return getIdTokenResult(auth.currentUser);
  }
  export function signOutAPI() {
    return signOut(auth)
  }
export function assignRole(email, firstName, lastName) {
  const assignRole = httpsCallable(functions, "assignRole");
  return assignRole({
    email,
    firstName,
    lastName,
  });
}

export function getUserProfile(uid) {
  const getUserProfile = httpsCallable(functions, "getUserProfile");
  return getUserProfile({ uid });
}

export function updateUserProfile(uid) {
  const updateUserProfile = httpsCallable(functions, "updateUserProfile");
  return updateUserProfile({ uid });
}
