import * as React from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  CardContent,
  Card, Skeleton
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useState } from "react";

export default function LatestPageSkeleton() {

  const [currentPage, setCurrentPage] = useState(1);
  const postPerPage = 2;

  const indexOfLastPage = currentPage * postPerPage;
  const indexOFFirstPost = indexOfLastPage - postPerPage;
  let count = Math.ceil(10 / postPerPage);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Container maxWidth="xl" sx={{ my: 3, mb: 6, p: { xs: 0, sm: 2 } }}>
      <Typography
        variant="h5"
        component="div"
        className="fw-semibold"
        sx={{
          color: "#fff",
          background: "var(--color-black)",
          py: 2,
          pl: 3,
          mb: 3,
        }}
      >
        LATEST POST
      </Typography>

      <Grid container spacing={2}>
        {/* main hero section */}
        
          <Grid item md={7} lg={8} xs={12} sx={{ position: "relative" }}>
            <Box
              className="rounded"
              sx={{
                pr: { md: 3, xs: 0 },
                width: "100%",
                height: { sm: "100%", xs: "auto" },
                display: "flex",
                boxShadow: "-1px 0px 5px rgba(0, 0, 0, 0.25)",
                flexDirection: "column",
              }}
            >
              {/* Two horizontal cards */}
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    width: "100%",
                    height: "100%",
                    boxShadow: 0,
                    alignSelf: "normal",
                    p: { sm: 2, xs: 1 },
                  }}
                >
                    <Box sx={{height:'100%', width:'100%'}}>
                    <Skeleton variant="rectangular"
                    sx={{
                      height:{md:200, xs:200},
                      width: { xs: '100%', sm:150 ,md: 200, lg: 250 },
                    }}
                  />
                    </Box>
                
                  <CardContent
                    sx={{
                      pl: { lg: 3, md: 1, sm: 3, xs: 1 },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      cursor: "pointer",
                      gap: 1,
                    }}
                    >
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Box sx={{display:'flex', gap:2}}>
                      <Skeleton variant="text"  sx={{ fontSize: '1rem',width:100}} />
                      <Skeleton variant="text"  sx={{ fontSize: '1rem',width:100}} />
                      <Skeleton variant="text"  sx={{ fontSize: '1rem',width:100}} />
                    </Box>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text"  sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width:{md:600, sm:500, xs:'100%'} }} />
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    width: "100%",
                    height: "100%",
                    boxShadow: 0,
                    alignSelf: "normal",
                    p: { sm: 2, xs: 1 },
                  }}
                >
                    <Box sx={{height:'100%', width:'100%'}}>
                    <Skeleton variant="rectangular"
                    sx={{
                      height:{md:200, xs:200},
                      width: { xs: '100%', sm:150 ,md: 200, lg: 250 },
                    }}
                  />
                    </Box>
                
                  <CardContent
                    sx={{
                      pl: { lg: 3, md: 1, sm: 3, xs: 1 },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      cursor: "pointer",
                      gap: 1,
                    }}
                    >
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Box sx={{display:'flex', gap:2}}>
                      <Skeleton variant="text"  sx={{ fontSize: '1rem',width:100}} />
                      <Skeleton variant="text"  sx={{ fontSize: '1rem',width:100}} />
                      <Skeleton variant="text"  sx={{ fontSize: '1rem',width:100}} />
                    </Box>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text"  sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width:{md:600, sm:500, xs:'100%'} }} />
                  </CardContent>
                </Card>
            </Box>

            {/* pagination */}
            <Pagination
              onChange={handleChange}
              count={count}
              page={currentPage}
              sx={{
                mt: "1rem",
                display: "flex",
                justifyContent: { md: "flex-end", xs: "center" },
                maxWidth: "100%",
              }}
              variant="outlined"
              shape="rounded"
              size="small"
            />
          </Grid>
          
        {/* aside section */}
        <Grid item md={5} lg={4} sm={12} xs={12}>
            <Card
                    className=" w-100 h-100 d-flex flex-column"
                    sx={{
                      alignSelf: "normal",
                      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                      p: { sm: 2, xs: 1 },
                      mt: {
                        xs: 2,
                        sm: 5,
                        md: "auto",
                      },
                    }}
                  >
                    <Box sx={{height:'100%'}}>
                    <Skeleton variant="rectangular"
                      sx={{
                        height:{md:'100%',sm:300, xs:200},
                        width: "100%",
                      }}
                    />
                    </Box>
                    <CardContent
                      sx={{
                        display: "flex",
                        p: 1,
                        flexDirection: "column",
                        alignItems: "start",
                        cursor: "pointer",
                        backgroundColor: "#FFFFFF",
                        gap: 2,
                      }}
                        >
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width:'100%' }} />
                      
                    {/* <Skeleton variant="text" sx={{ fontSize: '1rem', width:'100%' }} /> */}
                    <Box sx={{display:'flex', gap:2}}>
                      <Skeleton variant="text"  sx={{ fontSize: '1rem',width:100}} />
                      <Skeleton variant="text"  sx={{ fontSize: '1rem',width:100}} />
                      <Skeleton variant="text"  sx={{ fontSize: '1rem',width:100}} />
                    </Box>
                      
                    <Skeleton variant="text" sx={{ fontSize: '3rem', width:'100%', height:'100%' }} />
                    </CardContent>
            </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
