import * as React from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  Skeleton,
} from "@mui/material";
import Navbar from "../../../Components/Navbar/Navbar";
import FooterComponent from "../../../Components/Footer/Footer";
import { useParams } from "react-router-dom";

export default function BlogCategorySkeleton() {
  const { categoryParam } = useParams();
  return (
    <>
      <Navbar />
      <Box sx={{ mx: { md: 10, xs: 0 }, mt: 8 }}>
        <Typography
          variant="h5"
          component="div"
          className="fw-semibold"
          sx={{
            color: "#fff",
            background: "var(--color-black)",
            py: 2,
            mb: 3,
            textAlign: "center",
          }}
        >
          {categoryParam ? categoryParam.toUpperCase() : null}
        </Typography>
        <Box
          sx={{
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            px: {md:0.5, xs:1},
            mb: 5,
          }}
        >
          {/* Categorywise blog cards */}
          
                  <Card
                    component="div"
                    sx={{
                      display: "flex",
                      flexDirection: { sm: "row", xs: "column" }, position:'relative'
                    }}
                  >
                    <Box sx={{
                        width: { md:300, sm: 300, xs: "100%" },
                        height: { sm: 200, xs: 200 }
                      }}>
                        <Skeleton variant="rectangualar" sx={{height:'100%', width:'100%'}}/>
                    </Box>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 2,
                        width:{lg:'100%', md:'80%', sm:'70%'}
                      }}
                    >
                      <Skeleton variant="text" sx={{ fontSize: '2rem', width:{lg:900, md:630, sm:500, xs:'100%'} }} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width:150, position: "absolute",
                      display:{xs:'none', sm:'block'},
                          right: 10,
                          bottom: { sm: 0, xs: -3 }, mb:1}} />
                    </CardContent>
                    <Skeleton variant="circular" width={30} height={30} 
                        sx={{ position: "absolute",top:5, right: 5 }}
                    />
                  </Card>
                  <Card
                    component="div"
                    sx={{
                      display: "flex",
                      flexDirection: { sm: "row", xs: "column" }, position:'relative'
                    }}
                  >
                    <Box sx={{
                        width: { md:300, sm: 300, xs: "100%" },
                        height: { sm: 200, xs: 200 }
                      }}>
                        <Skeleton variant="rectangualar" sx={{height:'100%', width:'100%'}}/>
                    </Box>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 2,
                        width:{lg:'100%', md:'80%', sm:'70%'}
                      }}
                    >
                      <Skeleton variant="text" sx={{ fontSize: '2rem', width:{lg:900, md:630, sm:500, xs:'100%'} }} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width:150, position: "absolute",
                      display:{xs:'none', sm:'block'},
                          right: 10,
                          bottom: { sm: 0, xs: -3 }, mb:1}} />
                    </CardContent>
                    <Skeleton variant="circular" width={30} height={30} 
                        sx={{ position: "absolute",top:5, right: 5 }}
                    />
                  </Card>
                  <Card
                    component="div"
                    sx={{
                      display: "flex",
                      flexDirection: { sm: "row", xs: "column" }, position:'relative'
                    }}
                  >
                    <Box sx={{
                        width: { md:300, sm: 300, xs: "100%" },
                        height: { sm: 200, xs: 200 }
                      }}>
                        <Skeleton variant="rectangualar" sx={{height:'100%', width:'100%'}}/>
                    </Box>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 2,
                        width:{lg:'100%', md:'80%', sm:'70%'}
                      }}
                    >
                      <Skeleton variant="text" sx={{ fontSize: '2rem', width:{lg:900, md:630, sm:500, xs:'100%'} }} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width:150, position: "absolute",
                      display:{xs:'none', sm:'block'},
                          right: 10,
                          bottom: { sm: 0, xs: -3 }, mb:1}} />
                    </CardContent>
                    <Skeleton variant="circular" width={30} height={30} 
                        sx={{ position: "absolute",top:5, right: 5 }}
                    />
                  </Card>
                  
        </Box>
      </Box>
      <FooterComponent />
    </>
  );
}
