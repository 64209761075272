import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    companyname: "",
    city: "",
    message: "",
}

export const contactSlice = createSlice({
    name: "contact",
    initialState,
    reducers: {
        setFirstname:(state, action) => {
            state.firstname = action.payload.value
        },
        setLastname:(state, action) => {
            state.lastname = action.payload.value
        },
        setEmail:(state, action) => {
            state.email = action.payload.value
        },
        setPhone:(state, action) => {
            state.phone = action.payload.value
        },
        setCompanyName:(state, action) => {
            state.companyname = action.payload.value
        },
        setCity:(state, action) => {
            state.city = action.payload.value
        },
        setMessage:(state, action) => {
            state.message = action.payload.value
        },
    }
    }
);

export const { setFirstname, setLastname, setEmail, setPhone, setCompanyName, setCity, setMessage } = contactSlice.actions;
export const contactState = (state) => state.contact;
export default contactSlice.reducer