import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import {
  Typography,
  Box,
  IconButton,
  styled,
  Button,
  LinearProgress,
  Container,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import {
  movePopularBlogAsync,
  getPopularBlogAsync,
  deletePopularBlogAsync,
  setBlogForm,
  popularBlogState,
  setCategory,
  setDate,
  updateSectionObject,
} from "./reduxSlice";
import moment from "moment";
import EditNoteIcon from "@mui/icons-material/EditNote";
import BlogForm from "./Form";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function PopularList() {
  const dispatch = useDispatch();
  const { blogForm, popularCollection, loading } =
    useSelector(popularBlogState);
  const [postData, setPostData] = React.useState(null);
  const [toggle, setToggle] = React.useState(false);

  React.useEffect(() => {
    dispatch(getPopularBlogAsync({}));
  }, [dispatch]);

  return (
    <>
      {blogForm ? (
        <BlogForm data={postData} toggle={toggle} />
      ) : (
        <Container maxWidth="lg" className="mt-5">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h4" component="h4" textAlign="left">
              Popular Blog List
            </Typography>
            <Button
              onClick={() => {
                dispatch(setBlogForm({ value: true }));
                setToggle(false);
                setPostData();
              }}
              sx={{ px: "2.5rem" }}
              variant="contained"
              startIcon={<AddIcon />}
            >
              ADD
            </Button>
          </Box>

          <TableContainer
            sx={{ my: "3rem", mx: "auto", width: "100%" }}
            component={Paper}
          >
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">#</StyledTableCell>
                  <StyledTableCell align="left">Title</StyledTableCell>
                  <StyledTableCell align="left">Date</StyledTableCell>
                  <StyledTableCell align="center">Edit</StyledTableCell>
                  <StyledTableCell align="center">
                    Move To Blogs
                  </StyledTableCell>
                  <StyledTableCell align="right">Delete</StyledTableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <LinearProgress />
              ) : (
                <TableBody>
                  {popularCollection
                    ? popularCollection?.map((item, i) => {
                        return (
                          <StyledTableRow key={i}>
                            <StyledTableCell align="left">
                              {i + 1}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item.section[0].heading}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {moment(item.selectedDate).format("DD MMM YYYY")}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <IconButton
                                onClick={() => {
                                  dispatch(setBlogForm({ value: true }));
                                  setToggle(true);
                                  setPostData(item);
                                  dispatch(
                                    updateSectionObject({ value: item.section })
                                  );
                                  dispatch(
                                    setCategory({ value: item.category })
                                  );
                                  dispatch(
                                    setDate({ value: item.selectedDate })
                                  );
                                }}
                              >
                                <EditNoteIcon color="primary" />
                              </IconButton>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <IconButton
                                onClick={() => {
                                  const pass = item.id;
                                  dispatch(movePopularBlogAsync({ pass }));
                                }}
                                aria-label="move"
                              >
                                <ArrowForwardIosIcon color="primary" />
                              </IconButton>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <IconButton
                                onClick={() => {
                                  const pass = item.id;
                                  const deletePass = item.section;
                                  dispatch(
                                    deletePopularBlogAsync({ pass, deletePass })
                                  );
                                }}
                                aria-label="delete"
                              >
                                <DeleteIcon color="primary" />
                              </IconButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    : null}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Container>
      )}
    </>
  );
}
