import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  link: window.location.href,
  heading: "",
};

export const shareSlice = createSlice({
  name: "share",
  initialState,
  reducers: {
    setShareLink: (state, action) => {
      state.link = action.payload.value;
    },
    setHeading: (state, action) => {
      state.title = action.payload.value;
    },
  },
});

export const { setShareLink, setHeading } = shareSlice.actions;

export const shareState = (state) => state.share;

export default shareSlice.reducer;
