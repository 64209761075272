import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom'
import Homepage from './components/Pages/Homepage/HomePage';
import ExplorePage from './components/Pages/ExplorePage/ExplorePage';
import SignInPage from "./components/Components/Auth/components/SignInPage"
import Signup from "./components/Components/Auth/components/Signup";
import Forgot from "./components/Components/Auth/components/Forgot";
import Dashboard from './components/Components/Dashboard/Dashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import About from './components/Pages/AboutPage/About';
import Contact from './components/Pages/ContactPage/Contact';
import Profile from './components/Components/Profile/Profile';
// import HeroSectionSkeleton from './components/Components/HeroSection/Skeleton/App';
// import LatestPageSkeleton from './components/Components/LatestPage/Skeleton/App';
import HomePageSkeleton from './components/Pages/Homepage/Skeleton/App';
import BlogCategory from './components/Pages/BlogCategory/BlogCategory';
import ExplorePageSkeleton from './components/Pages/ExplorePage/Skeleton/App';
import BlogCategorySkeleton from './components/Pages/BlogCategory/Skeleton/App';


function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/explorepage" element={<ExplorePage />} />
      <Route
            path="/:blogparam"
            element={<ExplorePage />}
          />
      <Route path="/signin" element={<SignInPage />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/aboutus" element={<About />} />
      <Route path="/contact" element={<Contact />} /> 
      <Route path='/forgot' element={<Forgot />} />
      <Route path='/profile' element={<Profile />} />
      <Route path='/Homepage-skeleton' element={<HomePageSkeleton />} />
      <Route path='/Category/:categoryParam' element={<BlogCategory />} />
      <Route path='/explore-page-skeleton' element={<ExplorePageSkeleton />} />
      <Route path='/blog-category-skeleton' element={<BlogCategorySkeleton />} />
    </Routes>
    </>
  );
}

export default App;
