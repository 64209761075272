import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  List,
  Divider,
  Typography,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Button,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NotesIcon from "@mui/icons-material/Notes";
import GradeIcon from "@mui/icons-material/Grade";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useState } from "react";
import CommentList from "../CommentList/CommentList";
import Profile from "../Profile/Profile";
import { useSelector, useDispatch } from "react-redux";
import { dashboardState, setDashboardTab } from "./reduxSlice";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardTab from "./DashboardTab";
import SocailLinks from "../SocialLinks/SocailLinks";
import PopularList from "../PopularList/PopularList";
import CategoryList from "../CategoryList/CategoryList";
import AddLinkIcon from "@mui/icons-material/AddLink";
import CategoryIcon from "@mui/icons-material/Category";
import BlogList from "../BlogsList/App";
import { getSocialAsync } from "../SocialLinks/reduxSlice";
import { setBlogForm } from "../PopularList/reduxSlice";
import { setBlogListForm } from "../BlogsList/reduxSlice";
import { getCategoryAsync } from "../CategoryList/reduxSlice";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getSocialAsync({}));
    dispatch(getCategoryAsync({}));
  }, [dispatch]);
  const { collection, dashboardTab } = useSelector(dashboardState);

  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            color="inherit"
            sx={{ flexGrow: 1 }}
          >
            {collection?.companyName}
          </Typography>
          <Button
            size="small"
            sx={{ color: "#FFF" }}
            endIcon={<ArrowForwardIcon />}
            onClick={() => navigate("/")}
          >
            Home
          </Button>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          sx={{
            "& .MuiTypography-root": {
              color: "#000",
            },
            "& .MuiListItemIcon-root": {
              color: "#000",
            },
          }}
        >
          <ListItem
            onClick={() =>
              dispatch(setDashboardTab({ dashboardTab: "Dashboard" }))
            }
            disablePadding
            sx={{
              display: "block",
              bgcolor: dashboardTab === "Dashboard" ? "#1976d21a" : "#fff",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                color: "#000",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ style: { fontWeight: 600 } }}
                primary="Dashboard"
                sx={{ opacity: open ? 1 : 0, color: "#000" }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            onClick={() =>
              dispatch(setDashboardTab({ dashboardTab: "Profile" }))
            }
            disablePadding
            sx={{
              display: "block",
              bgcolor: dashboardTab === "Profile" ? "#1976d21a" : "#fff",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ style: { fontWeight: 600 } }}
                primary="Profile"
                sx={{ opacity: open ? 1 : 0, color: "#000" }}
              />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List
          sx={{
            "& .MuiTypography-root": {
              color: "#000",
            },
            "& .MuiListItemIcon-root": {
              color: "#000",
            },
          }}
        >
          <ListItem
            onClick={() => {
              dispatch(setDashboardTab({ dashboardTab: "BlogList" }));
              dispatch(setBlogListForm({ value: false }));
            }}
            disablePadding
            sx={{
              display: "block",
              bgcolor: dashboardTab === "BlogList" ? "#1976d21a" : "#fff",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ style: { fontWeight: 600 } }}
                primary="Blog List"
                sx={{ opacity: open ? 1 : 0, color: "#000" }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            onClick={() =>
              dispatch(setDashboardTab({ dashboardTab: "CommentList" }))
            }
            disablePadding
            sx={{
              display: "block",
              bgcolor: dashboardTab === "CommentList" ? "#1976d21a" : "#fff",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <NotesIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ style: { fontWeight: 600 } }}
                primary="Comment List"
                sx={{ opacity: open ? 1 : 0, color: "#000" }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            onClick={() =>
              dispatch(setDashboardTab({ dashboardTab: "SocialLinks" }))
            }
            disablePadding
            sx={{
              display: "block",
              bgcolor: dashboardTab === "SocialLinks" ? "#1976d21a" : "#fff",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <AddLinkIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ style: { fontWeight: 600 } }}
                primary="Social Links"
                sx={{ opacity: open ? 1 : 0, color: "#000" }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            onClick={() =>
              dispatch(setDashboardTab({ dashboardTab: "CategoryList" }))
            }
            disablePadding
            sx={{
              display: "block",
              bgcolor: dashboardTab === "CategoryList" ? "#1976d21a" : "#fff",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ style: { fontWeight: 600 } }}
                primary="Category List"
                sx={{ opacity: open ? 1 : 0, color: "#000" }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            onClick={() => {
              dispatch(setDashboardTab({ dashboardTab: "PopularList" }));
              dispatch(setBlogForm({ value: false }));
            }}
            disablePadding
            sx={{
              display: "block",
              bgcolor: dashboardTab === "PopularList" ? "#1976d21a" : "#fff",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <GradeIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ style: { fontWeight: 600 } }}
                primary="Popular Blog List"
                sx={{ opacity: open ? 1 : 0, color: "#000" }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {(dashboardTab === "Dashboard" && <DashboardTab />) ||
          (dashboardTab === "Profile" && <Profile />) ||
          (dashboardTab === "BlogList" && <BlogList />) ||
          (dashboardTab === "CommentList" && <CommentList />) ||
          (dashboardTab === "SocialLinks" && <SocailLinks />) ||
          (dashboardTab === "PopularList" && <PopularList />) ||
          (dashboardTab === "CategoryList" && <CategoryList />)}
      </Box>
    </Box>
  );
}
