import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Typography,
  Box,
  IconButton,
  styled,
  Button,
  TextField,
  Modal,
  Container,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  categoryState,
  getCategoryAsync,
  postCategoryAsync,
  setCategory,
  setPass,
  updateCategoryAsync,
  deleteCategoryAsync,
} from "./reduxSlice";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: { xs: 280, sm: 300, md: 300, lg: 300 },
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function CategoryList() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getCategoryAsync({}));
  }, [dispatch]);
  const { categoryCollection, category } = useSelector(categoryState);
  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Container maxWidth="lg" className="mt-5">
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 5 }}>
        <Typography variant="h4" component="h4" textAlign="left">
          Category List
        </Typography>
        <Button
          onClick={handleOpen}
          variant="contained"
          startIcon={<AddIcon />}
        >
          Add Category
        </Button>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ mt: "2rem", mb: "1rem" }}
            variant="h4"
            component="h4"
            textAlign="left"
          >
            Add Category
          </Typography>
          <TextField
            fullWidth
            size="small"
            placeholder="Category"
            margin="normal"
            required
            id="blogCategory"
            name="blogCategory"
            color="primary"
            defaultValue={update ? category : null}
            helperText={!category ? "Please provide category name" : null}
            sx={{ backgroundColor: "white", mt: 0, color: "#000" }}
            onChange={(e) => {
              dispatch(setCategory({ value: e.target.value }));
            }}
          />
          {update ? (
            <Button
              sx={{ mt: 1 }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                if (category) {
                  dispatch(updateCategoryAsync({}));
                  setUpdate(false);
                  handleClose();
                }
              }}
            >
              Update
            </Button>
          ) : (
            <Button
              sx={{ mt: 1 }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                if (category) {
                  dispatch(postCategoryAsync({}));
                  handleClose();
                }
              }}
            >
              ADD
            </Button>
          )}
        </Box>
      </Modal>
      <TableContainer sx={{ mx: "auto", maxWidth: "100%" }} component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">#</StyledTableCell>
              <StyledTableCell align="left">Category</StyledTableCell>
              <StyledTableCell align="center">Edit</StyledTableCell>
              <StyledTableCell align="center">Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categoryCollection
              ? categoryCollection?.map((item, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item.category}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton
                          aria-label="done"
                          onClick={() => {
                            handleOpen();
                            setUpdate(true);
                            dispatch(setCategory({ value: item.category }));
                            dispatch(setPass({ value: item.id }));
                          }}
                        >
                          <EditNoteIcon color="primary" />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton
                          aria-label="done"
                          onClick={() => {
                            const pass = item.id;
                            dispatch(deleteCategoryAsync({ pass }));
                          }}
                        >
                          <DeleteIcon color="primary" />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
