import * as React from "react";
import {
  Container,
  Breadcrumbs,
  Link,
  Typography,
  Box,
  TextField,
  Button,
  InputAdornment,
  Paper,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  popularBlogState,
  setBlogForm,
  setHeading,
  setBody,
  setSectionObject,
  setImage,
  postPopularBlogAsync,
  updatePopularBlogAsync,
  setDate,
  setCategory,
  updateSectionObject,
} from "./reduxSlice";
import MUIRichTextEditor from "mui-rte";
import { setDashboardTab } from "../Dashboard/reduxSlice";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import { useNavigate } from "react-router-dom";
import draftToHtml from "draftjs-to-html";
import { categoryState } from "../CategoryList/reduxSlice";
import { fromByteArray } from "base64-js";
import Compressor from "compressorjs";

export default function Form(props) {
  const [imgName, setImgName] = React.useState([]);
  const [defaultValueOfEditor, setDefaultValueOfEditor] = React.useState("");
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { section, loading } = useSelector(popularBlogState);
  const { categoryCollection } = useSelector(categoryState);

  const handleChange = (e, i) => {
    const file = e.target.files[0];
    setImgName([...imgName, file.name]);
    new Compressor(file, {
      quality: 0.8,
      success: async (compressedResult) => {
        const convertedFile = await bit64(compressedResult);
        const files = {
          file: convertedFile,
          name: file.name,
          type: file.type,
        };
        dispatch(setImage({ value: files, index: i }));
      },
    });
  };

  const bit64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileData = event.target.result;
        const base64String = fromByteArray(new Uint8Array(fileData));
        resolve(base64String);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const [propsCategory, setPropsCategory] = React.useState(
    props?.data?.category?.toLocaleUpperCase()
  );

  React.useEffect(() => {
    if (props.data?.id && props?.data?.section?.length > 0) {
      let stringified = [];

      for (let i = 0; i < props?.data?.section?.length; i++) {
        const contentHTML = convertFromHTML(props?.data?.section[i]?.body);
        const state = ContentState.createFromBlockArray(
          contentHTML.contentBlocks,
          contentHTML.entityMap
        );
        const raw = convertToRaw(state);
        const { blocks, entityMap } = raw;
        const fixedBlocks = blocks.map((block) => {
          return {
            ...block,
            type: block.text === "📷" ? "atomic" : block.type,
          };
        });
        const fixedEntityMap = {};
        for (const [key, value] of Object.entries(entityMap)) {
          if (value.type === "IMAGE") {
            value.data.url = value.data.src;
          }

          fixedEntityMap[key] = value;
        }
        stringified.push(
          JSON.stringify({
            blocks: fixedBlocks,
            entityMap: fixedEntityMap,
          })
        );
      }
      console.log("running");
      setDefaultValueOfEditor(stringified);
    }
  }, [dispatch, props?.data]);

  const removeSection = (i) => {
    const updatedArray = section.filter((element, index) => index !== i);
    dispatch(updateSectionObject({ value: updatedArray }));
  };

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            onClick={() => {
              dispatch(setBlogForm({ value: false }));
              dispatch(setDashboardTab({ dashboardTab: "PopularList" }));
            }}
            sx={{ cursor: "pointer", color: "#1976d2" }}
            underline="hover"
            color="inherit"
          >
            <ArrowBackIcon color="primary" sx={{ mr: 1 }} />
            Popular Blog List
          </Link>
          <Typography color="text.primary">Add Blog</Typography>
        </Breadcrumbs>
        <Grid container spacing={2} className=" mt-4">
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="category">Select Blog Category</InputLabel>
              <Select
                labelId="category"
                id="demo-simple-select"
                label="Select Blog Category"
                value={propsCategory ? propsCategory : ""}
                onChange={(e) => {
                  dispatch(
                    setCategory({ value: e.target.value.toLocaleUpperCase() })
                  );
                  setPropsCategory(e.target.value);
                }}
              >
                {categoryCollection
                  ? categoryCollection?.map((item, index) => (
                      <MenuItem
                        value={item.category.toLocaleUpperCase()}
                        key={index}
                      >
                        {item.category}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                defaultValue={dayjs(
                  props?.data?.selectedDate
                    ? props?.data?.selectedDate
                    : new Date()
                )}
                className="w-100"
                onChange={(e) => {
                  dispatch(setDate({ value: e.toString() }));
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {section.map((data, i) => {
            return (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Divider textAlign="center" sx={{ my: 2 }}>
                  <Chip label={`SECTION ${i + 1}`} />
                  <IconButton
                    aria-label="delete"
                    onClick={() => removeSection(i)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Divider>
                <Paper
                  sx={{
                    p: 2,
                    backgroundColor: "#e3e3e326",
                    boxShadow: "none",
                  }}
                >
                  <Typography variant="subtitle1" component="p">
                    Upload image ( image ratio must be 4:3 or 1024 x 768 )
                  </Typography>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    placeholder="No file chosen"
                    value={
                      props?.data?.section[i]?.img?.Location
                        ? props?.data?.section[i]?.img?.Location
                        : imgName[i]
                    }
                    autoFocus
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Button variant="contained" component="label">
                            Choose File
                            <input
                              hidden
                              multiple
                              accept="image/*"
                              type="file"
                              onChange={(e) => handleChange(e, i)}
                            />
                          </Button>
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: "white",
                        mt: 0,
                      },
                      readOnly: true,
                    }}
                  />

                  <Typography variant="subtitle1" component="p" mt="1rem">
                    Enter the post headline
                  </Typography>
                  <TextField
                    fullWidth
                    margin="normal"
                    required
                    defaultValue={
                      props?.data?.section[i]?.heading
                        ? props?.data?.section[i]?.heading
                        : null
                    }
                    id="heading"
                    placeholder="Headline*"
                    name="heading"
                    sx={{ backgroundColor: "white", mt: 0 }}
                    onChange={(e) => {
                      dispatch(setHeading({ index: i, value: e.target.value }));
                    }}
                  />

                  <Typography variant="subtitle1" component="p" mt="1rem">
                    Enter the post content
                  </Typography>
                  <Box
                    sx={{
                      minHeight: "250px",
                      border: 2,
                      borderColor: "#e9e9e9",
                      px: 2,
                      mb: 1,
                      backgroundColor: "white",
                    }}
                  >
                    <MUIRichTextEditor
                      label="Type something here..."
                      defaultValue={defaultValueOfEditor[i]}
                      controls={[
                        "title",
                        "bold",
                        "italic",
                        "underline",
                        "undo",
                        "redo",
                        "numberList",
                        "bulletList",
                        "clear",
                      ]}
                      onChange={(state) => {
                        let convertRaw = convertToRaw(
                          state.getCurrentContent()
                        );
                        let html = draftToHtml(convertRaw);
                        dispatch(
                          setBody({
                            value: html,
                            index: i,
                          })
                        );
                      }}
                    />
                  </Box>
                </Paper>
              </Box>
            );
          })}
          <Button
            sx={{ mt: 1, maxWidth: "220px", mx: 2 }}
            variant="contained"
            onClick={() => {
              dispatch(setSectionObject({}));
            }}
            startIcon={<AddIcon />}
          >
            ADD MORE SECTION
          </Button>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            my: 2,
            gap: 2,
            alignItems: "center",
          }}
        >
          {props.toggle ? (
            <LoadingButton
              loading={loading}
              loadingIndicator="Posting.."
              variant="contained"
              size="large"
              sx={{ py: 0.6 }}
              onClick={() => {
                const pass = props?.data?.id;
                dispatch(updatePopularBlogAsync({ pass, navigate }));
              }}
            >
              update
            </LoadingButton>
          ) : (
            <LoadingButton
              loading={loading}
              loadingIndicator="Posting.."
              variant="contained"
              size="large"
              sx={{ py: 0.6 }}
              onClick={() => dispatch(postPopularBlogAsync({ navigate }))}
            >
              Submit
            </LoadingButton>
          )}
        </Box>
      </Container>
    </>
  );
}
