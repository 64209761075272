import * as React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
  Paper,
  IconButton,
  LinearProgress,
  Pagination,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import BlogForm from "./Form";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  blogListState,
  deleteBlogAsync,
  getBlogAsync,
  setBlogListForm,
  moveToPopularAsync,
  updateSectionObject,
  setCategory,
  setDate,
} from "./reduxSlice";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function App() {
  const dispatch = useDispatch();
  const { blogForm, collection, loading } = useSelector(blogListState);
  const [postData, setPostData] = React.useState(null);
  const [toggle, setToggle] = React.useState(false);
  const sort = collection;
  const [currentPage, setCurrentPage] = React.useState(1);
  const postPerPage = 10;

  const indexOfLastPage = currentPage * postPerPage;
  const indexOFFirstPost = indexOfLastPage - postPerPage;
  const currentPost = sort.slice(indexOFFirstPost, indexOfLastPage);
  let count = Math.ceil(sort.length / postPerPage);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  React.useEffect(() => {
    dispatch(getBlogAsync({}));
  }, [dispatch]);

  return (
    <>
      {blogForm ? (
        <BlogForm data={postData} toggle={toggle} />
      ) : (
        <Container maxWidth="lg" className="mt-5">
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 5 }}>
            <Typography variant="h4" component="h4" textAlign="left">
              Blog List
            </Typography>
            <Button
              onClick={() => {
                dispatch(setBlogListForm({ value: true }));
                setToggle(false);
                setPostData();
              }}
              sx={{ px: "2.5rem" }}
              variant="contained"
              startIcon={<AddIcon />}
            >
              ADD
            </Button>
          </Box>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">#</StyledTableCell>
                  <StyledTableCell align="left">Title</StyledTableCell>
                  <StyledTableCell align="left">Posted Date</StyledTableCell>
                  <StyledTableCell align="center">Edit</StyledTableCell>
                  <StyledTableCell align="center">
                    Move To Popular
                  </StyledTableCell>
                  <StyledTableCell align="right">Delete</StyledTableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <LinearProgress />
              ) : (
                <TableBody>
                  {collection
                    ? currentPost?.map((item, index) => {
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="left">
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item.section[0].heading}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {moment(item.selectedDate).format("DD MMM YYYY")}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <IconButton
                                onClick={() => {
                                  dispatch(setBlogListForm({ value: true }));
                                  setToggle(true);
                                  setPostData(item);
                                  dispatch(
                                    updateSectionObject({ value: item.section })
                                  );
                                  dispatch(
                                    setCategory({ value: item.category })
                                  );
                                  dispatch(
                                    setDate({ value: item.selectedDate })
                                  );
                                }}
                              >
                                <EditNoteIcon color="primary" />
                              </IconButton>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <IconButton
                                onClick={() => {
                                  const pass = item.id;
                                  dispatch(moveToPopularAsync({ pass }));
                                }}
                                aria-label="move"
                              >
                                <ArrowForwardIosIcon color="primary" />
                              </IconButton>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  const pass = item.id;
                                  dispatch(
                                    updateSectionObject({ value: item.section })
                                  );
                                  dispatch(deleteBlogAsync({ pass }));
                                }}
                              >
                                <DeleteIcon color="primary" />
                              </IconButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    : null}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {collection.length <= 10 ? null : (
            <Pagination
              onChange={handleChange}
              count={count}
              page={currentPage}
              sx={{
                float: "right",
                mt: 2,
                mx: "auto",
              }}
              variant="outlined"
              shape="rounded"
              size="small"
            />
          )}
        </Container>
      )}
    </>
  );
}
