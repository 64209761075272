export default function submitForm(
  fName,
  lName,
  Email,
  phone,
  company,
  city,
  message
) {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    to: "marketing@bizconnectevents.com",
    subject: `Contacted by ${fName}`,
    text: `Name: ${fName} ${lName},
    Company Name: ${company},
    Email: ${Email},
    Message: ${message},
    Phone: ${phone}
                   `,
    html: `
    <table id="customers" style="font-family: Arial, Helvetica, sans-serif;border-collapse: collapse;width: 100%;">
    <tr>
    <th style="border: 1px solid #ddd;padding: 8px;padding-top: 12px;padding-bottom: 12px;text-align: left;background-color: #F87330;color: white;font-weight:bold;width:30%">Name</th>
    <th style="border: 1px solid #ddd;padding: 8px;padding-top: 12px;padding-bottom: 12px;text-align: left;background-color: #F87330;color: white;font-weight:bold;">Value</th>
  </tr>
    <tr>
      <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Name</td>
      <td style="border: 1px solid #ddd;padding: 8px;">${fName} ${lName}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Email</td>
      <td style="border: 1px solid #ddd;padding: 8px;">${Email}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Phone Number</td>
      <td style="border: 1px solid #ddd;padding: 8px;">${phone}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Company Name</td>
      <td style="border: 1px solid #ddd;padding: 8px;">${company}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">City</td>
      <td style="border: 1px solid #ddd;padding: 8px;">${city}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Message</td>
      <td style="border: 1px solid #ddd;padding: 8px;">${message}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Site</td>
      <td style="border: 1px solid #ddd;padding: 8px;"><a href='#'>BizConnect Blogs</a></td>
    </tr>
  </table>
  
          `,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    "https://asia-south1-biz-contact-form-8001a.cloudfunctions.net/sendMail",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      console.log(result);
    })
    .catch((error) => console.log("error", error));

  // Show alert
  document.querySelector("#alert").style.display = "flex";
  document.querySelector("#alert").style.width = "100%";

  // Hide alert after 3 seconds
  setTimeout(function () {
    document.querySelector("#alert").style.display = "none";
  }, 8000);
  // Clear form
  document.getElementById("contact-form").reset();
}
