import { Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getSocialAsync } from "../SocialLinks/reduxSlice";
import { dashboardState } from "../Dashboard/reduxSlice";

const Footer = () => {
  const dispatch = useDispatch();
  const { collection } = useSelector(dashboardState);
  useEffect(() => {
    dispatch(getSocialAsync({}));
  }, [dispatch]);
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#1E1E1E",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 3,
          pb: 2,
        }}
      >
        <Typography variant="subtitle1" component="p" color="#FFF">
          Contact us{" "}
          <a
            style={{ textDecoration: "none", color: "var(--color-blue)" }}
            href={"mailto:" + collection?.email}
          >
            {collection?.email}
          </a>
        </Typography>
        {/* text box */}
        <Typography
          variant="caption"
          sx={{
            color: "#eee",
          }}
        >
          Copyright ©{new Date().getFullYear()} All rights reserved by{" "}
          {collection?.companyName}
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
