import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postSocialLink, getSocialLinks, updateSocialLink } from "./reduxAPI";

const initialState = {
  youtube: "",
  facebook: "",
  linkedin: "",
  twitter: "",
  instagram: "",
  loading: false,
  collection: "",
};

export const postSocialAsync = createAsyncThunk(
  "socialLinks/postSocialLink",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { twitter, facebook, instagram, linkedin, youtube } =
        getState().socialLinks;
      const response = await postSocialLink(
        youtube,
        facebook,
        linkedin,
        twitter,
        instagram
      );
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getSocialAsync = createAsyncThunk(
  "socialLinks/getSocialLinks",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const sessionToken = "_";
      const response = await getSocialLinks(sessionToken);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const updateSocialAsync = createAsyncThunk(
  "socialLinks/updateSocialLink",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { twitter, facebook, instagram, linkedin, youtube } =
        getState().socialLinks;
      const response = await updateSocialLink(
        twitter,
        facebook,
        instagram,
        linkedin,
        youtube
      );
      dispatch(getSocialAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const socialLinkSlice = createSlice({
  name: "socialLinks",
  initialState,
  reducers: {
    setYoutube: (state, action) => {
      state.youtube = action.payload.value;
    },
    setFacebook: (state, action) => {
      state.facebook = action.payload.value;
    },
    setLinkedin: (state, action) => {
      state.linkedin = action.payload.value;
    },
    setTwitter: (state, action) => {
      state.twitter = action.payload.value;
    },
    setInstagram: (state, action) => {
      state.instagram = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postSocialAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(postSocialAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postSocialAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getSocialAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSocialAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.collection = action.payload.data;
        if (state.collection) {
          state.facebook = state.collection.facebook;
          state.instagram = state.collection.instagram;
          state.linkedin = state.collection.linkedin;
          state.twitter = state.collection.twitter;
          state.youtube = state.collection.youtube;
        }
      })
      .addCase(getSocialAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateSocialAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateSocialAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateSocialAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  setYoutube,
  setFacebook,
  setInstagram,
  setLinkedin,
  setTwitter,
} = socialLinkSlice.actions;
export const socialLinkState = (state) => state.socialLinks;
export default socialLinkSlice.reducer;
